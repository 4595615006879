import { takeLatest } from "redux-saga/effects";
import actions from "../actionTypes";
import {
  getCandidatesDuplicates,
  deleteCandidates,
  mergeAndDeleteCandidates,
  sendEmail,
  listEmail,
  deletePerson,
  mergePerson,
  globalSearch,
  attachCandidate,
  addAvailability,
  addTimeOff,
  bookingOperation,
  confirmAllBookingOperation,
  removeAvailability,
  deleteTimeOff,
  getBookingDetails,
  confirmOrUnconfrimTimeOff,
  listEmails
} from "../workers/candidateModalWorker";

export function* watchGetCandidateDuplicates() {
  yield takeLatest(actions.CANDIDATE_DUPLICATES, getCandidatesDuplicates);
}

export function* watchSendEmail() {
  yield takeLatest(actions.CANDIDATE_EMAIL, sendEmail);
}

export function* watchListEmail() {
  yield takeLatest(actions.CANDIDATE_EMAIL_LIST, listEmail);
}

export function* watchListEmails() {
  yield takeLatest(actions.EMAIL_LIST, listEmails);
}

export function* watchDeleteCandidates() {
  yield takeLatest(actions.CANDIDATE_DELETE, deleteCandidates);
}

export function* watchDeletePerson() {
  yield takeLatest(actions.PERSON_DELETE, deletePerson);
}

export function* watchMergePerson() {
  yield takeLatest(actions.PERSON_DELETE_MERGE, mergePerson);
}

export function* watchGlobalSearch() {
  yield takeLatest(actions.GLOBAL_SEARCH, globalSearch);
}

export function* watchAttachCandidates() {
  yield takeLatest(actions.ATTACH_NEW_CANDIDATE, attachCandidate);
}

export function* watchMergeAndDeleteCandidates() {
  yield takeLatest(actions.CANDIDATE_MERGE_DELETE, mergeAndDeleteCandidates);
}

export function* watchAddAvailability() {
  yield takeLatest(actions.ADD_AVAILABILITY, addAvailability);
}

export function* watchAddTimeOff() {
  yield takeLatest(actions.ADD_TIMEOFF, addTimeOff);
}

export function* watchRemoveAvailability() {
  yield takeLatest(actions.REMOVE_AVAILABILITY, removeAvailability);
}

export function* watchDeleteTimeOff() {
  yield takeLatest(actions.DELETE_TIMEOFF, deleteTimeOff);
}

export function* watchBookingCalendarOperations() {
  yield takeLatest(actions.CALENDAR_BOOKING_OPERATION, bookingOperation);
}

export function* watchConfirmAllBookingOperation() {
  yield takeLatest(actions.CONFIRM_ALL_BOOKING_OPERATION, confirmAllBookingOperation);
}

export function* watchGetBookingDetails() {
  yield takeLatest(actions.GET_BOOKING_DETAILS, getBookingDetails);
}

export function* watchConfirmOrUnconfrimTimeOff() {
  yield takeLatest(actions.CONFIRM_TIME_OFF, confirmOrUnconfrimTimeOff);
}
