const LABEL_CONSTANTS = {
  SAVE_AND_CONTINUE_BTN: "SAVE & CONTINUE",
  SEND_BTN: "SEND",
  CONFIRM_BTN: "CONFIRM",
  SUBMIT_BTN: "Submit",
  DELETE_BTN: "Delete",
  CANCELBTN: "CANCEL",
  OK_BTN: "OK",
  POST_BTN: "POST",
  EDIT_BTN: "EDIT",
  DOWNLOAD_BTN: "Download",
  EMAIL_BTN: "Email",
  ADD_SUPPLIER: "Add Supplier",
  DISABLE_ROW: "disabled-row",
  CANDIDATE_SELECTION_WARN: "Please select atleast one candidate.",
  BOOKING_SELECTION_WARN: "Please select at least one booking.",
  CANDIDATE_DELETE_WARN: "You can select only one candidate at a time",
  BOOKING_DELETE_WARN: "You can select only one booking at a time",
  LOGIN: {
    HEADER_LABEL: "Login to your Account!",
    PASSWORD_LABEL: "Enter password",
    EMAIL_LABEL: "Email address",
    USERNAME_LABEL: "Username",
    REMEMBER_LABEL: "Remember me",
    FORGOT_LABEL: "Forgot Password?",
    BUTTON_LABEL: "Login",
    SECURE_LABEL: "Your Info is safely secured",
    API_END_POINT: "baseModule/userManagement/login",
    REQUEST_RESET_PASSWORD_END_POINT: "baseModule/userManagement/requestResetPassword",
    COMPLETE_RESET_PASSWORD_END_POINT: "baseModule/userManagement/requestCompleteResetPassword",
    GET_START_DAY_ENDPOINT: "systemAdmin/systemUserManagement/getStartDayOfWeek",
    REM_ME: "rememberMe"
  },
  SUBMENU_TABS: {
    DRIVER_MGMT: "Driver Management",
    CANDIDATE_MGMT: "Candidate Management",
    SITE_MGMT: "Site Management",
    BOOKING_MGMT: "Booking Calendar",
    MASTER_SETUP: "Master Setup",
    USER_MANAGEMENT_SETUP: "User Management",
    APPLICATION_SETUP: "Application Setup",
    MANAGE_PAY_RATES: "Pay and Charge Rates",
    DOCUMENT: "Documents",
    PENDING_DOCUMENTS: "Pending Items",
    GENERATE_PAYRATE_TAB: "Generate Document",
    HOLIDAY_TAB: "Holidays",
    COST_ANALYSIS_REPORT: "Client Reports",
    CANDIDATE_PERFORMACE_TAB: "Candidate Performance",
    INFRINGEMENT_TAB: "Infringement",
    BOOKING_SETUP_TAB: "System Setup",
    SUPPLIER_TAB: "STS",
    ACTIVE_TAB: "/home",
    VERIFY_BOOKING_TAB: "Verify Booking",
    CLIENT_MGMT_TAB: "Client Management",
    REPORTS_TAB: "Reports",
    CANDIDATE_MGMT_PLANNING: "Candidate Management Planning"
  },
  CANDIDATE_FILTER: {
    DELETE_PERSON_BTN: "Delete",
    ADD_CANDIDATE_BTN: "Add",
    ADD_BOOKING_BTN: "Add Booking",
    VERIFY_ALL: "Verify All",
    UNVERIFY_ALL: "Unverify All",
    CLIENT_FILTER_PLACEHOLDER: "Client",
    SITE_FILTER_PLACEHOLDER: "Site",
    STATUS_FILTER_PLACEHOLDER: "Status",
    BRANCH_FILTER_PLACEHOLDER: "Branches",
    TAGS_FILTER_PLACEHOLDER: "Tags",
    PROCESSED: "Processed",
    DONOTUSE: "DoNotUse",
    INACTIVE: "Inactive",
    ACTIVE: "Active",
    PENDING: "Pending",
    UNPROCESSED: "Unprocessed"
  },
  CANDIDATE_TABLE: {
    FORENAME: "Forename",
    FORENAME_KEY: "forename",
    FORENAME_SEARCH_KEY: "forename",

    SURNAME: "Surname",
    SURNAME_SEARCH_KEY: "surname",
    SURNAME_KEY: "surname",

    FULLNAME: "Full Name",
    FULLNAME_KEY: "fullname",

    STATUS: "Status",
    STATUS_KEY: "status",
    STS_PAGE_STATUS_KEY: "Status",

    PAYTIER: "Pay Tier",
    PAYTIER_KEY: "payment_tier",
    PAYTIER_SEARCH_KEY: "paytier",

    EMAIL: "Send Email",
    PHONE: "Call",
    MDELETE: "Merge & Delete",
    ATTACH_CANDIDATE: "Attach Candidate",
    MPTY: "",
    ACTION: "Action",
    ROW_KEY: "ObjectId",
    DISABLE: "DoNotUse",
    PAY_KEY_DAILY: "CandidatePaymentTier",

    TAGS_TITLE: "Tags",
    BRANCHES_TITLE: "Branches",
    TAGS_KEY: "Tags",

    BRANCHES: "Branches",
    BRANCHES_KEY: "Branches",
    BRANCHES_SEARCH_KEY: "branch"
  },
  DAILY_BOOKING: {
    START_DATE: "Start Date",
    START: "Start",
    BOOK: "Book",
    END: "End",
    START_DATE_KEY: "StartTime",
    END_DATE: "End Date",
    END_DATE_KEY: "EndTime",
    START_TIME: "Start Time",
    START_TIME_KEY: "StartTime",
    END_TIME: "End Time",
    END_TIME_KEY: "EndTime",
    ACTUAL_TIME: "Actual Time",
    ACTUAL_TIME_KEY: "ActualStartTime",
    ACTUAL_END_TIME: "ActualEndTime",
    CLIENT: "Client",
    CLIENT_KEY: "awr_group_name",
    SITE: "Site",
    SITE_KEY: "ClientName",
    TYPE: "Type",
    TYPE_KEY: "ShiftTypeName",
    LENGTH: "Length",
    CURRENT_LENGTH_KEY: "CurrentLength",
    LENGTH_KEY: "POALength",
    LATE: "Late",
    NIGHT_OUT: "N/O",
    VERIFIED: "Verified",
    VERIFIED_KEY: "PayrollVerified",
    CANCEL_REASON: "Cancel Reason",
    CANCEL_REASON_KEY: "CancelReason",
    BREAK: "Break",
    BREAK_KEY: "BreakLength",
    POA: "POA",
    TOTAL: "Total",
    POA_KEY: "POALength",
    SUPPLIER: "STS",
    DETAILS: "Details",
    REF: "Ref",
    REF_KEY: "BookingReference",
    CANDIDATE: "Candidate",
    CANDIDATE_KEY: "full_name",
    BOOKING_TIME: "Booking Time",
    BOOKING_TIME_KEY: "StartTime",
    REASON_CODE_TITLE: "Reason",
    REASON_CODE_KEY: "ReasonCode",
    BOOKED_KEY: "CreateDate",
    BOOKED_TITLE: "Booked",
    STS: "STS",
    STS_KEY: "SuppliersName",
    ACTION: "Action",
    HOLD_FROM_APP_TITLE: "App",
    HOLD_FROM_APP_KEY: "HoldFromApp"
  },
  SUB_HEADER_MENU: {
    HOME: "Home",
    AGENCY_USER: "Planning",
    AGENCY_ADMIN: "Admin",
    AGENCY_SUPER_OPERATOR: "Resourcing",
    FINANCE_USER: "Finance",
    CLIENT_USER: "Client",
    CLIENT_TIME_SHEET: "ClientTimesheet",
    CLIENT_FINANCE_USER: "ClientFinance",
    SYSTEM_ADMIN: "System Admin",
    WEBSITE_LINK: "Website Link",
    MOBILE_LINK: "Mobile Link",
    TASKS: "Tasks",
    PENDING_BOOKING: "PendingBooking",
    APPROVALS: "Approvals"
  },
  DELETE_PERSON_MODAL: {
    DELETE_TITLE: "Delete a Person",
    DELETE_TEXT: "You are about to delete a person from DRS",
    NOTE_TEXT:
      // eslint-disable-next-line max-len
      "Note: You can only delete people who have never been assigned to a booking. whether the booking was billed or not. You can merge two people together however by using second option below.",
    WARN_TITLE: "Warning:",
    WARN_TEXT: "This process cannot be undone",
    WARN_TEXT_DELETE: "You are about to delete a person which cannot be undone",
    WARN_TEXT_DELETE_MERGE: "You are about to merge or delete a person which cannot be undone",
    DELETE_OPT_1: "Delete person and associated candidate profiles", // "Permanently delete this person and all associated candidates rec.",
    DELETE_OPTION_2:
      // eslint-disable-next-line max-len
      "Delete person and associated candidate profiles and transfer associated candidate activity including Bookings, Status, Holidays, Ratings, Payroll, Infringements, KPI's and performance",
    // eslint-disable-next-line max-len
    // "Move all the candidates records currently associated with this person to someone else, then permanently delete this person.",
    DELETE_OPT_1_VAL: 1,
    DELETE_OPTION_2_VAL: 2,
    MOVE_CANDIDATES: "Move Candidates to",
    MOVE_CANDIDATES_PLACEHOLDER: "No Person Selected",
    SEARCH_WARN: "Please input at least 4 characters of person name.",
    DELETE_PERSON_BTN: "Delete Person"
  },
  ADD_USER_MODAL: {
    USER_TITLE: "Add User",
    ADD_BTN: "SAVE"
  },
  USER_MODAL: {
    DELETE_USER_TXT: "You are about to delete a user. Please confirm."
  },
  CANDIDATE_MODAL: {
    DELETE_TITLE: "Merge and Delete Candidate Records",
    DELETE_TEXT: "You are about delete one more Candidate Records from DRS",
    DELETE_CANDIDATE_TABLE_ITEM: "Are you sure you want to delete this item?",
    ERROR_TEXT:
      // eslint-disable-next-line max-len
      "Note: If a Candidate has ever been assigned to a booking you must merge them into another candidate.",
    MERGE_SELECT: "Merge selected",
    MERGE_PROMPT: "Select candidates  to Merge and Delete",
    MERGE_BTN: "Merge Candidate",
    DEL_CAND_BTN: "Delete Candidate",
    TABLE: {
      SELECTED: "Selected",
      STATUS: "Status",
      C_TYPE: "Candidate Type",
      C_TYPE_KEY: "Type",
      BOOK: "Bookings",
      BOOK_KEY: "BookingCount",
      DESC: "Description",
      NAME_KEY: "Name",
      ROW_KEY: "CandidateObjectId"
    }
  },
  EMAIL_CANDIDATE_MODAL: {
    TO: "To",
    SUBJECT: "Subject",
    MESSAGE: "Message",
    EMAIL_PLACEHOLDER: "Please select email",
    SUBJECT_PLACEHOLDER: "Please enter subject",
    MESSAGE_PLACEHOLDER: "Please enter message"
  },
  CANDIDATE_AVAILABILITY_MODAL: {
    ADD_AVAILABILITY: "Add Availability",
    ADD_AVAILABILITY_ITEM: "addAvailability",
    START_DATE: "Start Date",
    START_DATE_ITEM: "startDate",
    DAYS_TO_REPEAT: "Days to Repeat",
    DAYS_TO_REPEAT_ITEM: "daysToRepeat",
    REPEAT_FOR_WEEKS: "Repeat for weeks",
    REPEAT_FOR_WEEKS_ITEM: "weeksToRepeat",
    DETAILS: "Details",
    DETAILS_ITEM: "details",
    ADD_TIME_OFF: "Add Time Off",
    ADD_TIME_OFF_ITEM: "addTimeOff",
    DETAILS_PLACEHOLDER: "Please enter details",
    SELECT_PLACEHOLDER: "Please select",
    TYPE: "Type",
    TYPE_ITEM: "type",
    START_TIME_ITEM: "startTime",
    END_TIME_ITEM: "endTime",
    END_DATE: "End Date",
    END_DATE_ITEM: "endDate",
    TIME: "Time",
    TIME_FORMAT: "HH:mm:ss",
    DATE_FORMAT: "YYYY-MM-DD",
    ISO_T: "T"
  },
  TIME_FORMAT: "HH:mm:ss",
  CTA_MODAL: {
    HEADER: {
      DECLINE: "Decline Booking",
      CONFIRM: "Confirm Booking",
      START: "Start Booking",
      END: "End Booking",
      REPEAT: "Assign Candidate To Matching",
      RESET: "Reset Booking",
      CANCEL: "Cancel Booking",
      EDIT: "Edit Booking",
      CONFIRM_CANCEL: "Confirm Cancellation"
    },
    STATUS: {
      ST_DECLINE: "Decline",
      ST_CONFIRM: "Confirm",
      ST_START: "Start",
      ST_END: "End",
      ST_REPEAT: "Repeat",
      ST_RESET: "Reset",
      ST_CANCEL: "Cancel",
      ST_SMS: "SMS",
      ST_CHANGE_STIME: "ChangeStartTime",
      ST_CHANGE_SHIFT_LENGTH: "ChangeExpectedShiftLength"
    },
    BOOKING_STATUS: {
      PENDING_ST: "Pending",
      AWAIT_ST: "AwaitingConfirmation",
      CONFIRM_ST: "Confirmed",
      START_ST: "Started",
      COMPLETE_ST: "Complete",
      AWAIT_CANCEL_ST: "AwaitingCancel",
      CANCEL_ST: "Cancelled",
      TOTAL: "Total"
    },
    BUTTON: {
      CANCEL: "CANCEL BOOKING",
      START: "START",
      END: "END",
      CONFIRM: "CONFIRM",
      DECLINE: "DECLINE",
      SUBMIT: "SUBMIT",
      RESET: "RESET",
      EDIT: "EDIT",
      REPEAT: "Repeat"
    },
    MESSAGE: {
      ST_DATE: "Please select start date",
      ET_DATE: "Please select end date"
    },
    S_DATE: "Start Date",
    E_DATE: "End Date",
    S_DATE_KEY: "StartTime",
    S_ONLY_TIME: "Start Time",
    S_ONLY_TIME_KEY: "StartTimeKey",
    E_DATE_KEY: "EndTime",
    ACTUAL_S_DATE: "Actual Start Date",
    ACTUAL_E_DATE: "Actual End Date",
    ACTUAL_S_TIME: "Actual Start Time",
    ACTUAL_E_TIME: "Actual End Time",
    BOOKING_DATE: "Booking Date",
    BOOKING_TIME: "Booking Time",
    ACTUAL_S_DATE_KEY: "ActualStartDate",
    ACTUAL_E_DATE_KEY: "ActualEndDate",
    ACTUAL_S_TIME_KEY: "ActualStartTime",
    ACTUAL_E_TIME_KEY: "ActualEndTime",
    BOOKING_DATE_KEY: "BookingDate",
    BOOKING_TIME_KEY: "BookingTime",
    C_LATE: "Candidate Late",
    C_LATE_KEY: "candidateLate",
    NEW_START_DATE: "New Start Date",
    NEW_END_DATE: "New End Date",
    NEW_START_TIME: "New Start Time",
    BREAK: "Break",
    POA: "POA",
    OTHER_WORK: "Other Work",
    DRIVE_TIME: "Drive Time",
    OTHER_WORK_KEY: "OtherWork",
    DRIVE_TIME_KEY: "DriveTime",
    REASON: "Reason",
    PROMPT_MSG: "Are you sure, you want to continue?",
    CANCEL_REASON: "Reasons for cancellation?",
    SL_REASON: "Select Reason",
    SELECT_MSG: "Selecet the relevant bookings for this action:",
    DEFAULT_MODAL_WIDTH: "50%",
    CHANGE_STIME: "Change Start Time",
    CHANGE_SHIFT_LENGTH: "Change Expected Shift Length",
    CHANGE_STIME_KEY: "ChangeStartTime",
    CHANGE_SHIFT_LENGTH_KEY: "ChangeExpectedShiftLength",
    SHIFT_LENGTH: "Expected Shift Length",
    SHIFT_LENGTH_KEY: "ExpectedShiftLengthTicks",
    WARN_TEXT: "Warning: Changing the booking time may decline the candidate!",
    WARN_TEXT2: "Note: You will need to re-confirm the booking after changing the Start Time",
    WARN_TEXT3: "Note: You will need to re-confirm the booking after changing the Expected Length",
    ONLY_MINUTE: "HH:mm",
    S_LENGTH: "Shift Length",
    S_LENGTH_KEY: "ShiftLengthKey"
  },
  SEARCH_PLACEHOLDER: "Search by keyword",
  MD_MODAL_HEADER: "Merge and Delete Driver",
  EMAIL_MODAL_HEADER: "Send Email",
  CALL_SUPPLIER_HEADER: "Call",
  ATTACH_MODAL_HEADER: "Attach Candidate",
  DELETE_PERSON_MODAL_HEADER: "Delete Person",
  ADD_USER_MODAL_HEADER: "Add User",
  EDIT_MODAL_HEADER: "Edit User",
  DELETE_USER_MODAL_HEADER: "Delete User",
  CHANGE_PASSWORD_MODAL_HEADER: "Create/change Password",
  ADD_AVAILABILITY_MODAL_HEADER: "Add Availability/Time Off",
  ASSIGN_BOOKING_MODAL_HEADER: "Assign Candidate to a Booking",
  CANDIDATE_APPLICATION_MODAL: "Candidate Application",
  CANDIDATE_IMPORT_MODAL: "Candidate Import",
  HOURS_WORKED_IMPORT_MODAL_TITLE: "Hours Worked Import",
  DELETE_CANDIDATE_HOLIDAYS: "Delete Candidate Holidays",
  UPDATE_PERSON_NOTE: "Update Person Note",
  SEND_SMS_MODAL_HEADER: "Send Message",
  EMAIL_MODAL_WIDTH: "40%",
  AVAILABILITY_MODAL_WIDTH: "40%",
  MESSAGE_SITE_MODAL_HEADER: "SMS Site",
  MESSAGE_SITE_MODAL_WIDTH: "40%",
  ADD_SUPPLIER_MODAL_HEADER: "Add STS",
  ATTACH_MODAL_WIDTH: "30%",
  DEFAULT_MODAL_WIDTH: "75%",
  USER_MODAL_WIDTH: "40%",
  GO_TO_HOME: "Back Home",
  LOGIN_BACK: "Login Back",
  RELOAD: "Reload",
  ERROR_PAGE: {
    NOT_FOUND: "pageNotFound",
    NO_AUTH: "notAuthenticated",
    NO_ACCESS: "noAccessToPage",
    COMP_ERROR: "componentError",
    SERVER_ERROR: "serverError",
    HOME: "/",
    LOGIN: "/login"
  },
  CENTER: "center",
  ACTION: "Action",
  LEFT: "left",
  RIGHT: "right",
  NO_TAGS: "No Tags Found",
  SITE_TABLE: {
    CLIENT: "Client",
    CLIENT_KEY: "Client",
    SITE: "Site",
    SITE_KEY: "Name",
    DEFAULT_SHIFT_TYPE: "Default Shift type",
    DEFAULT_SHIFT_TYPE_KEY: "DefaultShiftTypeName",
    TAGS: "Tags",
    TAGS_KEY: "Tags",
    BRANCHES: "Branches",
    BRANCHES_KEY: "Branches",
    STATUS: "Status",
    STATUS_KEY: "Status",
    EMAIL: "Send Email",
    PHONE: "Call",
    LOCATION: "Location",
    ACTION: "Action",
    ROW_KEY: "ObjectId",
    DISABLE: "DoNotUse",
    MPTY: ""
  },
  SITE_FILTER: {
    ADD_SITE_BTN: "Add Site",
    CLIENT_FILTER_PLACEHOLDER: "Client",
    STATUS_FILTER_PLACEHOLDER: "Status",
    BRANCH_FILTER_PLACEHOLDER: "Branch",
    TAGS_FILTER_PLACEHOLDER: "Tags",
    PROCESSED: "Processed",
    DONOTUSE: "DoNotUse",
    INACTIVE: "Inactive",
    ACTIVE: "Active",
    UNPROCESSED: "Unprocessed"
  },
  USER_TABLE: {
    USERLIST: "Username",
    USERLIST_KEY: "Username",
    ROLE: "Roles",
    ROLE_KEY: "Roles",
    STATUS: "Status",
    STATUS_KEY: "status",
    MPTY: "",
    ACTION: "Action",
    ACTION_KEY: "Action",
    BRANCHES: "Branches",
    BRANCHES_KEY: "TagNames",
    SITE_BRANCHES_KEY: "SiteBranches",
    SITE_BRANCHES_TITLE: "Site Branches",
    EMAIL_ADDRESSES_TITLE: "Email Address",
    EMAIL_ADDRESSES_KEY: "EmailAddresses",
    SITES: "Sites"
  },
  USER_FILTER: {
    ADD_USER_BTN: "Add User",
    CLIENT_FILTER_PLACEHOLDER: "Client",
    USER_ROLE_PLACEHOLDER: "User Roles",
    INACTIVE: "Inactive",
    ACTIVE: "Active"
  },
  CLIENT_MANAGEMENT: {
    ADD_CLIENT_BTN: "Add New Client",
    ADD_CLIENT_MODAL: "Add Client",
    EDIT_CLIENT_MODAL: "Edit Client",
    DELETE_CLIENT_MODAL: "Delete Client",
    CLIENT_MODAL_WIDTH: "40%"
  },
  EMAIL_SITE_MODAL: {
    TO: "To",
    SUBJECT: "Subject",
    MESSAGE: "Message",
    EMAIL_PLACEHOLDER: "Please select email",
    SUBJECT_PLACEHOLDER: "Please enter subject",
    MESSAGE_PLACEHOLDER: "Please enter message"
  },
  SMS_SITE_MODAL: {
    PHONENUMBER: "Choose Phone Number",
    MESSAGE: "Message",
    MESSAGE_PLACEHOLDER: "Please enter message"
  },
  ASSIGN_CANDIDATE: {
    VIEW_PROFILE: "View Profile",
    VIEW_CALENDAR: "View Calendar",
    ASSIGN: "ASSIGN",
    SMS: "SMS"
  },
  ADD_BOOKING: {
    DATE: "Date",
    SITE: "Site",
    CLIENT: "Client",
    CLIENT_KEY: "ClientObjectId",
    NO_BOOKINGS: "Number of Booking",
    NO_BOOKINGS_KEY: "NumberOfBookings",
    NO_OFWEEKS: "Number Of Weeks",
    NO_OFWEEKS_KEY: "NumberOfWeeks",
    RUN_DETAILS: "Run Details",
    RUN_DETAILS_KEY: "RunDetails",
    NIGHT_OUT: "Night Out",
    NIGHT_OUT_KEY: "NightOut",
    TIME: "Time [XX:XX]",
    TIME_KEY: "Time",
    SHIFT_TYPE: "Shift Type",
    SHIFT_TYPE_KEY: "ShiftType",
    REASON_CODE: "Reason Code",
    REASON_CODE_KEY: "ReasonCode",

    REFERENCE: "Reference",
    DUTY_TIME: "Duty Time [XX.XX]",
    DUTY_TIME_KEY: "DutyTime",
    REPEAT_BOOKING: "Repeat Booking",
    REPEAT_BOOKING_KEY: "RepeatBooking",
    REPEAT: "Repeat",
    ROTA: "Rota",
    ROTA_ON: "ON",
    ROTA_ON_KEY: "RotaDaysOn",
    ROTA_OFF: "OFF",
    ROTA_OFF_KEY: "RotaDaysOff",
    END_DATE: "End Date",
    END_DATE_KEY: "EndDate"
  },
  LOCATION_MODAL_HEADER: "Site Location",
  LOCATION_MODAL_WIDTH: "90%",
  ADD_BOOK_MODAL_HEADER: "New Booking",
  DETAIL_PLANNING_MODAL_HEADER: "Detailed Planning",
  ADD_BOOK_MODAL_WIDTH: "80%",
  WEEKFIELDS: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  SUPPLIER_MANAGEMENT: {
    SUPPLIER_TABLE: {
      NAME: "Supplier",
      NAME_KEY: "Name",
      CONTACT_NAME: "Contact",
      CONTACT_NAME_KEY: "ContactName",
      TAGS: "Tags",
      BRANCHES: "Branches",
      SLA: "SLA",
      SLA_EXPIRY: "SLA Expiry Date",
      SLA_EXPIRY_KEY: "SLAExpiryDate",
      ACCOUNT_NUMBER: "Reference",
      ACCOUNT_NUMBER_KEY: "AccountNumber",
      SEND_EMAIL_TITLE: "Send Email",
      SEND_EMAIL_KEY: "EmailAddresses",
      CALL_TITLE: "Call",
      CALL_KEY: "PhoneNumbers"
    },
    STS_TABLE: {
      FORENAME: "Forename",
      FORENAME_KEY: "forename",
      SURNAME: "Surname",
      SURNAME_KEY: "surname",
      C_TYPE: "Candidate Type",
      C_TYPE_KEY: "Type",
      TAGS: "Tags"
    }
  },
  ADD_STS_MODAL_HEADER: "Add STS Candidate",
  SEARCH_WARN: "Please input at least 4 characters of person name.",
  EDIT_SUPPLIER_MODAL_HEADER: "Supplier",
  DELETE_SUPPLIER_MODAL_HEADER: "Delete STS",
  DELETE_SUPPLIER_TXT: "You are about to delete a STS candidate. Please confirm.",
  DELETE_ATTACHMENT_TXT: "Are you sure you want to delete this file?",
  DELETE_MODAL_HEADER: "Delete Attachment",
  ATTACHMENTS_MODAL_HEADER: "Attachments"
};

const LABELS = Object.freeze(LABEL_CONSTANTS);

export default LABELS;
