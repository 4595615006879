import _constants from "../../constants/constants";
// import defaultPayload from "../../constants/defaultPayloads";
import { isClientUser } from "../../utils/reusableFunctions";

const {
  HTTP_REQUEST_METHODS: { POST, GET, DELETE }
} = _constants;

const API_ENDPOINTS = {
  login: { endPoint: "baseModule/userManagement/login", method: POST },
  getClientList: {
    endPoint: `agencyUser/driverManagment/${
      JSON.parse(localStorage.getItem("loggedInUser"))?.roles?.some(
        item => item.RoleName !== "Admin"
      )
        ? "getUserClientList"
        : "getClientList"
    }`,
    method: GET,
    // successMessage: "Fetched client list successfully",
    errorMessage: "Failed to fetch client list"
  },
  getSiteList: {
    endPoint: `agencyUser/driverManagment/${isClientUser() ? "getUserSiteList" : "getSiteList"}`,
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to fetch site list"
  },
  getUserList: {
    endPoint: "systemAdmin/systemUserManagement/getUserListing?statuses=",
    method: GET,
    // successMessage: "Fetched User List successfully",
    errorMessage: "Failed to fetch user list"
  },
  getUserListPaged: {
    endPoint: "systemAdmin/systemUserManagement/getUserListingPaged",
    method: POST,
    // successMessage: "Fetched User List successfully",
    errorMessage: "Failed to fetch user list"
  },
  massResetPW: {
    endPoint: "systemAdmin/systemUserManagement/massResetPassword",
    method: POST,
    successMessage: "Reset Users Password Successfully",
    errorMessage: "Failed to Reset Users Password"
  },
  addNewUser: {
    endPoint: "systemAdmin/systemUserManagement/addNewUser",
    method: POST,
    successMessage: "Successfully added New User",
    errorMessage: "Failed to add New User"
  },
  deleteUser: {
    endPoint: "systemAdmin/systemUserManagement/deleteUser",
    method: POST,
    successMessage: "Successfully deleted the User",
    errorMessage: "Failed to delete the User"
  },
  updateUserDetails: {
    endPoint: "systemAdmin/systemUserManagement/updateUserDetails?requestObject=",
    method: POST,
    successMessage: "Successfully updated the User",
    errorMessage: "Failed to update the User"
  },
  changePassword: {
    endPoint: "systemAdmin/systemUserManagement/changeUserPassword",
    method: POST,
    successMessage: "Successfully changed the password",
    errorMessage: "Failed to change the password"
  },
  addBooking: {
    endPoint: "agencyUser/driverManagment/addBooking",
    method: POST,
    successMessage: "Successfully created booking",
    errorMessage: "Failed to create booking"
  },
  getDriverCalendarEvents: {
    endPoint: "agencyUser/driverManagment/getBookingList",
    method: POST,
    // successMessage: "Fetched bookings successfully",
    errorMessage: "Failed to fetch bookings"
  },
  getRibbonData: {
    endPoint: "agencyUser/driverManagment/getWorkHoursRibbon",
    method: POST,
    // successMessage: "Fetched ribbon data successfully",
    errorMessage: "Failed to fetch ribbon data"
  },
  getBookingDetails: {
    endPoint: "agencyUser/bookingCalendar/getBookingDetails",
    method: POST,
    // successMessage: "Fetched booking details successfully",
    errorMessage: "Failed to fetch booking details"
  },
  getTagList: {
    endPoint: "agencyUser/driverManagment/getTagList",
    method: GET,
    // successMessage: "Fetched tag list successfully",
    errorMessage: "Failed to fetch tag list"
  },
  getInsuranceCategoryList: {
    endPoint: "agencyUser/driverManagment/getInsuranceCategoryList",
    method: GET,
    // successMessage: "Fetched category list successfully",
    errorMessage: "Failed to fetch category list"
  },
  getNationalityList: {
    endPoint: "agencyUser/driverManagment/getNationalityList",
    method: GET,
    // successMessage: "Fetched nationality list successfully",
    errorMessage: "Failed to fetch nationality list"
  },
  getCandidateTypesList: {
    endPoint: "agencyUser/driverManagment/getCandidateTypesList",
    method: GET,
    // successMessage: "Fetched candidate type list successfully",
    errorMessage: "Failed to fetch candidate type list"
  },
  getCandidateList: {
    endPoint: "agencyUser/driverManagment/getCandidateListing",
    method: POST,
    // data: defaultPayload.candidateListingBasic,
    // successMessage: "Fetched candidate list successfully",
    errorMessage: "Failed to fetch candidate list"
  },
  getCandidateListPaged: {
    endPoint: "agencyUser/driverManagment/getCandidateListingPaged",
    method: POST,
    // data: defaultPayload.candidateListingBasic,
    // successMessage: "Fetched candidate list successfully",
    errorMessage: "Failed to fetch candidate list"
  },
  inviteESignCandidates: {
    endPoint: "agencyUser/driverManagment/inviteESignCandidates",
    method: POST,
    // data: defaultPayload.candidateListingBasic,
    successMessage: "Candidates have been successfully registered.",
    errorMessage: "ESign has not been configured for this platform"
  },
  getCandidateInviteApp: {
    endPoint: "agencyUser/driverManagment/candidatesInviteApp",
    method: POST,
    // data: defaultPayload.candidateListingBasic,
    // successMessage: "Fetched candidate list successfully",
    errorMessage: "Failed to invite candidates"
  },
  addCandidateImport: {
    endPoint: "agencyUser/driverManagment/candidateImport?requestObject=",
    method: POST,
    // successMessage: "Fetched assign candidate list successfully",
    errorMessage: "Failed to import candidates"
  },
  addHoursWorkedImport: {
    endPoint: "agencyUser/driverManagment/hoursWorkedImport?",
    method: POST,
    // successMessage: "Fetched assign candidate list successfully",
    errorMessage: "Failed to import hours"
  },
  updatePersonNote: {
    endPoint: "candidateUser/candidate/updatePersonNote",
    method: POST,
    // successMessage: "Fetched assign candidate list successfully",
    errorMessage: "Failed to update person note."
  },
  changeCandidateStatus: {
    endPoint: "agencyUser/driverManagment/changeCandidateStatus",
    method: POST,
    // successMessage: "Fetched assign candidate list successfully",
    errorMessage: "Failed to change candidate status"
  },
  getAssignCandidateList: {
    endPoint: "agencyUser/driverManagment/getAgencyCandidates",
    method: POST,
    // successMessage: "Fetched assign candidate list successfully",
    errorMessage: "Failed to fetch assign candidate list"
  },
  getListSupplierCandidates: {
    endPoint: "agencyUser/driverManagment/listSupplierCandidates",
    method: POST,
    // successMessage: "Fetched supplier candidate list successfully",
    errorMessage: "Failed to fetch supplier candidate list"
  },
  getWorkingTimeSummary: {
    endPoint: "agencyUser/driverManagment/getWorkingTimeSummary",
    method: POST,
    // successMessage: "Fetched working time summary successfully",
    errorMessage: "Failed to fetch working time summary"
  },
  getDailyBookingList: {
    endPoint: "agencyUser/driverManagment/getDailyBookingList",
    method: POST,
    // successMessage: "Fetched daily booking list successfully",
    errorMessage: "Failed to fetch daily booking list"
  },
  getMonthlyBookingList: {
    endPoint: "agencyUser/driverManagment/getMonthlyBookingList",
    method: POST,
    // successMessage: "Fetched monthly booking list successfully",
    errorMessage: "Failed to fetch monthly booking list"
  },
  getWeeklyBookingList: {
    endPoint: "agencyUser/driverManagment/getWeeklyBookingList",
    method: POST,
    // successMessage: "Fetched weekly booking list successfully",
    errorMessage: "Failed to fetch weekly booking list"
  },
  deleteCandidates: {
    endPoint: "agencyUser/driverManagment/deleteCandidate",
    method: POST,
    successMessage: "Successully deleted candidates",
    errorMessage: "Failed to delete candidates"
  },
  deletePerson: {
    endPoint: "agencyUser/driverManagment/deletePerson?deletePersonObjectId=",
    method: POST,
    successMessage: "Successully deleted person",
    errorMessage: "Failed to delete person"
  },
  mergePerson: {
    endPoint: "agencyUser/driverManagment/mergePerson",
    method: POST,
    successMessage: "Successully merged person",
    errorMessage: "Failed to merge person"
  },
  attachNewCandidate: {
    endPoint: "agencyUser/driverManagment/attachNewCandidate",
    method: POST,
    successMessage: "Successully attached candidate",
    errorMessage: "Failed to attach candidate"
  },
  mergeAndDeleteCandidates: {
    endPoint: "agencyUser/driverManagment/mergeCandidate",
    method: POST,
    successMessage: "Successully merged candidates",
    errorMessage: "Failed to merge candidates"
  },
  addCandidateAvailability: {
    endPoint: "agencyUser/driverManagment/addCandidateAvailability",
    method: POST,
    successMessage: "Successully added availability",
    errorMessage: "Failed to add availability"
  },
  addCandidateTimeOff: {
    endPoint: "agencyUser/driverManagment/addCandidateTimeOff",
    method: POST,
    successMessage: "Successully added timeoff",
    errorMessage: "Failed to add timeoff"
  },
  removeCandidateAvailability: {
    endPoint: "agencyUser/driverManagment/removeAvailability",
    method: POST,
    successMessage: "Successully removed availability",
    errorMessage: "Failed to remove availability"
  },
  deleteCandidateTimeOff: {
    endPoint: "agencyUser/driverManagment/deleteTimeOff",
    method: POST,
    successMessage: "Successully deleted timeoff",
    errorMessage: "Failed to delete timeoff"
  },
  getLeadSourceList: {
    endPoint: "agencyUser/driverManagment/getLeadSourceList",
    method: GET,
    // successMessage: "Fetched lead source list successfully",
    errorMessage: "Failed to fetch lead source list"
  },
  ListActiveCandidates: {
    endPoint: "agencyUser/driverManagment/ListActiveCandidates",
    method: POST,
    // successMessage: "Fetched all active candidates successfully",
    errorMessage: "Failed to fetch active candidates"
  },
  ListBookings: {
    endPoint: "agencyUser/driverManagment/ListBookings",
    method: POST,
    // successMessage: "Fetched bookings list successfully",
    errorMessage: "Failed to fetch bookings list"
  },
  PersonDetail: {
    endPoint: "agencyUser/driverManagment/PersonDetail",
    method: POST,
    // successMessage: "Fetched person details successfully",
    errorMessage: "Failed to fetch person details"
  },
  AttachNewCandidate: {
    endPoint: "agencyUser/driverManagment/AttachNewCandidate",
    method: POST,
    successMessage: "Successfully attached new candidates",
    errorMessage: "Failed to attach candidates"
  },
  ChangeCandidateStatus: {
    endPoint: "agencyUser/driverManagment/ChangeCandidateStatus",
    method: POST,
    successMessage: "Successfully changed candidate status",
    errorMessage: "Failed to change candidate status"
  },
  DrivingLicenseCapability: {
    endPoint: "agencyUser/driverManagment/DrivingLicenseCapability",
    method: POST,
    successMessage: "Driving License Capability is updated",
    errorMessage: "Failed to fetch driving license capability"
  },
  DrivingLicenseEndorsement: {
    endPoint: "agencyUser/driverManagment/DrivingLicenseEndorsement",
    method: POST,
    successMessage: "Driving License Endorsement is updated",
    errorMessage: "Failed to endorse driving license"
  },
  AddNewCandidate: {
    endPoint: "agencyUser/driverManagment/AddNewCandidate",
    method: POST,
    successMessage: "Successfully added new candidate!",
    errorMessage: "Failed to add new candidate"
  },
  AddNewAddress: {
    endPoint: "agencyUser/driverManagment/addContact?objectId=",
    method: POST,
    successMessage: "Successfully added contact",
    errorMessage: "Failed to add contact"
  },
  AddNewAdditionalAddress: {
    endPoint: "agencyUser/driverManagment/addCandidateAdditionalDetails?personObjectId=",
    method: POST,
    successMessage: "Successfully added address",
    errorMessage: "Failed to add address"
  },
  AddMultipleDocuments: {
    endPoint: "agencyUser/driverManagment/attachMultiDocument?requestObject=",
    method: POST,
    successMessage: "Successfully attached document",
    errorMessage: "Failed to attach document"
  },
  getCandidateDuplicates: {
    endPoint: "agencyUser/driverManagment/getCandidateDuplicates?candidateObjectId=",
    method: GET,
    // successMessage: "Successfully fetched duplicate candidates",
    errorMessage: "Failed to fetch duplicate candidates"
  },

  AddLicenseInformation: {
    endPoint: "agencyUser/driverManagment/addDrivingLicense&DigiCard?requestObject=",
    method: POST,
    successMessage: "Successfully added license information",
    errorMessage: "Failed to add license information"
  },
  GetDrivingLicenseCapabilityList: {
    endPoint: "agencyUser/driverManagment/getDrivingLicenseCapabilityList",
    method: GET,
    // successMessage: "Fetched driving license capability list successfully",
    errorMessage: "Failed to fetch driving license capability list"
  },
  GetDrivingLicenseEndorsementList: {
    endPoint: "agencyUser/driverManagment/getDrivingLicenseEndorsementList",
    method: GET,
    // successMessage: "Fetched driving license endorsement list successfully",
    errorMessage: "Failed to fetch driving license endorsement list"
  },
  GetDrivingLicenseCapabilityTypesList: {
    endPoint: "agencyUser/driverManagment/getDrivingLicenseCapabilityTypesList",
    method: GET,
    // successMessage: "Fetched driving license capability types list successfully",
    errorMessage: "Failed to fetch driving license capability types list"
  },
  GetDrivingLicenseEndorsementTypesList: {
    endPoint: "agencyUser/driverManagment/getDrivingLicenseEndorsementTypesList",
    method: GET,
    // successMessage: "Fetched driving license endorsement types list successfully",
    errorMessage: "Failed to fetch driving license endorsement types list"
  },
  sendEmail: {
    endPoint: "/agencyUser/driverManagment/sendEmail",
    method: POST,
    successMessage: "Sent email successfully.",
    errorMessage: "Failed to send email."
  },
  listEmails: {
    endPoint: "/agencyUser/driverManagment/listEntitiesEmails",
    method: POST,
    // successMessage: "Fetched all email successfully.",
    errorMessage: "Failed to fetch emails."
  },
  listEmail: {
    endPoint: "/agencyUser/driverManagment/listCandidateEmails?candidateObjectId=",
    method: GET,
    // successMessage: "Fetched all email successfully.",
    errorMessage: "Failed to fetch candidate emails."
  },
  AddInductionsBans: {
    endPoint: "agencyUser/driverManagment/addInductionsBans?requestObject=",
    method: POST,
    successMessage: "Successfully added Inductions And Bans information",
    errorMessage: "Failed to add Inductions And Bans information"
  },
  getIdentificationNameList: {
    endPoint: "agencyUser/driverManagment/getIdentificationNameList",
    method: GET,
    // successMessage: "Fetched Identification Name list successfully",
    errorMessage: "Failed to fetch Identification Name list"
  },
  AddQualifications: {
    endPoint: "agencyUser/driverManagment/addQualificationsAndIdentifications?requestObject=",
    method: POST,
    successMessage: "Successfully added Qualifications And Identifications information",
    errorMessage: "Failed to add Qualifications And Identifications information"
  },
  AddLicenceAdditionalDetails: {
    endPoint: "agencyUser/driverManagment/addDigiCard?requestObject=",
    method: POST,
    successMessage: "Successfully added Digital Tachograph information",
    errorMessage: "Failed to add Digital TachographDigital Tachograph information"
  },
  GlobalSearch: {
    endPoint: "agencyUser/driverManagment/globalSearch?searchString=",
    method: GET,
    // successMessage: "Successfully searched person.",
    errorMessage: "Failed to search person."
  },
  getPaymentTierList: {
    endPoint: "agencyUser/driverManagment/getPaymentTierList",
    method: GET,
    // successMessage: "Fetched Payment Tier list successfully",
    errorMessage: "Failed to fetch Payment Tier list"
  },
  getSupplierList: {
    endPoint: "agencyUser/driverManagment/getSupplierList",
    method: GET,
    // successMessage: "Fetched Supplier list successfully",
    errorMessage: "Failed to fetch Supplier list"
  },
  getServiceCompanyList: {
    endPoint: "agencyUser/driverManagment/getServiceCompanyList",
    method: GET,
    // successMessage: "Fetched Service Company list successfully",
    errorMessage: "Failed to fetch Service Company list"
  },
  getContactTypeList: {
    endPoint: "agencyUser/driverManagment/getContractTypeList",
    method: GET,
    // successMessage: "Fetched Contact Type list successfully",
    errorMessage: "Failed to fetch Contact Type list"
  },
  getExpenseTypeList: {
    endPoint: "agencyUser/driverManagment/getBookingExpenseTypeList",
    method: GET,
    // successMessage: "Fetched Expense Type list successfully",
    errorMessage: "Failed to fetch Expense Type list"
  },
  AddPayrollDetails: {
    endPoint: "agencyUser/driverManagment/addPayrollInformation?requestObject=",
    method: POST,
    successMessage: "Successfully added Payroll information",
    errorMessage: "Failed to add Payroll information"
  },
  AddPreferenceDetails: {
    endPoint: "agencyUser/driverManagment/PersonTimePreference",
    method: POST,
    successMessage: "Successfully added Preferences information",
    errorMessage: "Failed to add Preferences information"
  },
  AddWorkHistory: {
    endPoint: "agencyUser/driverManagment/addWorkHistory?requestObject=",
    method: POST,
    successMessage: "Successfully added Work History information",
    errorMessage: "Failed to add Work History information"
  },
  getSatisfactionLevelList: {
    endPoint: "agencyUser/driverManagment/getSatisfactionLevelList",
    method: GET,
    // successMessage: "Fetched Satisfaction level list successfully",
    errorMessage: "Failed to fetch Satisfaction level list"
  },
  getReasonForLeavingList: {
    endPoint: "agencyUser/driverManagment/getReasonForLeavingList",
    method: GET,
    // successMessage: "Fetched Reasons list successfully",
    errorMessage: "Failed to fetch Reasons list"
  },
  getNonConversionList: {
    endPoint: "agencyUser/driverManagment/getNonConversionReasonList",
    method: GET,
    // successMessage: "Fetched Non conversion list successfully",
    errorMessage: "Failed to fetch Non conversion list"
  },
  AddHumanResource: {
    endPoint: "agencyUser/driverManagment/PersonHumanResource",
    method: POST,
    successMessage: "Successfully added Human Resource information",
    errorMessage: "Failed to add Human Resource information"
  },
  getCandidateRatingTypeList: {
    endPoint: "agencyUser/driverManagment/getCandidateRatingTypeList",
    method: GET,
    // successMessage: "Fetched Rating type list successfully",
    errorMessage: "Failed to fetch Rating type list"
  },
  AddCandidateRating: {
    endPoint: "agencyUser/driverManagment/addCandidateRating?candidateObjectId=",
    method: POST,
    successMessage: "Successfully added Rating information",
    errorMessage: "Failed to add Rating information"
  },
  getCandidateInfringementsTypeList: {
    endPoint: "agencyUser/driverManagment/getCandidateInfringementTypeList",
    method: GET,
    // successMessage: "Fetched Infringements type list successfully",
    errorMessage: "Failed to fetch Infringements type list"
  },
  AddCandidateInfringements: {
    endPoint: "agencyUser/driverManagment/addCandidateInfringements?candidateObjectId=",
    method: POST,
    successMessage: "Successfully added Infringements information",
    errorMessage: "Failed to add Infringements information"
  },
  getBookingOperations: {
    endPoint: "agencyUser/driverManagment/bookingFulfilment",
    method: POST,
    successMessage: "Booking operation successfull",
    errorMessage: "Booking operation failed"
  },
  getHolidaysList: {
    endPoint: "agencyUser/driverManagment/getCandidateHolidayHistory?candidateObjectId=",
    method: GET,
    // successMessage: "Fetched Holidays list successfully",
    errorMessage: "Failed to fetch Holidays list"
  },
  getNotesList: {
    endPoint: "agencyUser/driverManagment/getNotesList?targetObjectId=",
    method: GET,
    // successMessage: "Fetched Notes list successfully",
    errorMessage: "Failed to fetch Notes list"
  },
  AddNotesActivity: {
    endPoint: "agencyUser/driverManagment/addNotes?requestObject=",
    method: POST,
    successMessage: "Successfully added Notes information",
    errorMessage: "Failed to add Notes information"
  },
  getStatementList: {
    endPoint: "agencyUser/driverManagment/getStatementOptionList",
    method: GET,
    // successMessage: "Fetched Statement list successfully",
    errorMessage: "Failed to fetch Statement list"
  },
  getCandidatePerformanceTypesList: {
    endPoint: "agencyUser/driverManagment/getCandidatePerformanceTypesList",
    method: GET,
    // successMessage: "Fetched Types list successfully",
    errorMessage: "Failed to fetch Types list"
  },
  AddPerformanceActivity: {
    endPoint: "agencyUser/driverManagment/addCandidatePerformance?requestObject=",
    method: POST,
    successMessage: "Successfully added Performance information",
    errorMessage: "Failed to add Performance information"
  },
  siteSendEMail: {
    endPoint: "agencyUser/driverManagment/sendEmail",
    method: POST,
    successMessage: "Sent email successfully.",
    errorMessage: "Failed to send email."
  },
  siteAllEMailList: {
    endPoint: "agencyUser/driverManagment/listSiteEmails?siteObjectId=",
    method: GET,
    // successMessage: "Fetched all email successfully.",
    errorMessage: "Failed to fetch site emails."
  },
  sitePhoneNumberList: {
    endPoint: "agencyUser/driverManagment/getPhoneNumbersList?objectId=",
    method: GET,
    // successMessage: "Fetched all PhoneNumber successfully.",
    errorMessage: "Failed to fetch phonenumber."
  },
  siteSendSMS: {
    endPoint: "agencyUser/driverManagment/sendSiteSMS",
    method: POST,
    successMessage: "Message sent successfully.",
    errorMessage: "Failed to send Message."
  },
  getTasksList: {
    endPoint: "agencyUser/driverManagment/getTicketsList?ObjectId=",
    method: GET,
    // successMessage: "Fetched Tasks list successfully",
    errorMessage: "Failed to fetch Tasks list"
  },
  AddTasksActivity: {
    endPoint: "agencyUser/driverManagment/addTickets?requestObject=",
    method: POST,
    successMessage: "Successfully added Tasks information",
    errorMessage: "Failed to add Tasks information"
  },
  getSmsHistory: {
    endPoint: "agencyUser/driverManagment/getSMSHistory?candidateObjectId=",
    method: GET,
    // successMessage: "Fetched SMS history list successfully",
    errorMessage: "Failed to fetch SMS history list"
  },
  SendSMS: {
    endPoint: "agencyUser/driverManagment/sendSMS?personObjectId=",
    method: POST,
    successMessage: "SMS sent successfully",
    errorMessage: "Failed to send SMS"
  },
  getEmailHistory: {
    endPoint: "agencyUser/driverManagment/getEmailHistory?objectId=",
    method: GET,
    // successMessage: "Fetched Email history list successfully",
    errorMessage: "Failed to fetch Email history list"
  },
  AddSmartBoxActivity: {
    endPoint: "agencyUser/driverManagment/sendSmartBoxMessage",
    method: POST,
    successMessage: "Successfully added SmartBox information",
    errorMessage: "Failed to add SmartBox information"
  },
  getContact: {
    endPoint: "agencyUser/driverManagment/getContacts",
    method: GET,
    // successMessage: "Fetched address successfully.",
    errorMessage: "Failed to fetch address."
  },
  addSiteContact: {
    endPoint: "agencyUser/driverManagment/addContact",
    method: POST,
    successMessage: "Successfully Added Contact Information",
    errorMessage: "Failed to add Contact Information"
  },
  addSiteDetails: {
    endPoint: "agencyUser/siteManagment/addSite",
    method: POST,
    successMessage: "Successfully Added General Details",
    errorMessage: "Failed to add General Details"
  },
  getDayOfWeekList: {
    endPoint: "agencyUser/driverManagment/getDayOfWeekList",
    method: GET,
    // successMessage: "Fetched Days of Weeks successfully",
    errorMessage: "Failed to Fetched Days of Weeks"
  },
  getShiftType: {
    endPoint: "agencyUser/driverManagment/getDefaultShiftTypesList",
    method: GET,
    // successMessage: "Fetched Default Shift Type successfully",
    errorMessage: "Failed to Fetched Default Shift Type"
  },
  addSiteDocument: {
    endPoint: "agencyUser/siteManagment/addSiteDocuments",
    method: POST,
    successMessage: "Successfully Added Documents Information",
    errorMessage: "Failed to add Documents Information"
  },
  getSiteDocumentType: {
    endPoint: "agencyUser/driverManagment/getSiteDocumentTypesList",
    method: GET,
    // successMessage: "Fetched Document Type successfully",
    errorMessage: "Failed to Fetched Document Type"
  },
  getRatingsDetails: {
    endPoint: "agencyUser/driverManagment/getCandidateRatingsDetails?candidateObjectId=",
    method: GET,
    // successMessage: "Fetched Ratings list successfully.",
    errorMessage: "Failed to fetch Ratings list."
  },
  getInfringementsDetails: {
    endPoint: "agencyUser/driverManagment/getInfringements?objectId=",
    method: GET,
    // successMessage: "Fetched Infringements list successfully.",
    errorMessage: "Failed to fetch Infringements list."
  },
  getPerformanceDetails: {
    endPoint: "agencyUser/driverManagment/getCandidatePerformanceDetails?candidateObjectId=",
    method: GET,
    // successMessage: "Fetched Performance list successfully.",
    errorMessage: "Failed to fetch Performance list."
  },
  getSmartBoxDetailsList: {
    endPoint: "agencyUser/driverManagment/getSmartBoxMessageHistory?objectId=",
    method: GET,
    // successMessage: "Fetched SmartBox list successfully.",
    errorMessage: "Failed to fetch SmartBox list."
  },
  updateCandidateRating: {
    endPoint: "agencyUser/driverManagment/updateCandidateRating?candidateObjectId=",
    method: POST,
    successMessage: "Successfully updated Rating successfully.",
    errorMessage: "Failed to update rating details."
  },
  updateCandidateInfringement: {
    endPoint: "agencyUser/driverManagment/updateCandidateInfringements?candidateObjectId=",
    method: POST,
    successMessage: "Successfully updated Infringement successfully.",
    errorMessage: "Failed to update infringement details."
  },
  updateCandidatePerformance: {
    endPoint: "agencyUser/driverManagment/updateCandidatePerformance?requestObject=",
    method: POST,
    successMessage: "Successfully updated performance successfully.",
    errorMessage: "Failed to update performance details."
  },
  updateCandidateNotes: {
    endPoint: "agencyUser/driverManagment/updateObjectNote?requestObject=",
    method: POST,
    successMessage: "Successfully updated notes successfully.",
    errorMessage: "Failed to update notes details."
  },
  updateCandidateSmartBox: {
    endPoint: "agencyUser/driverManagment/updateSmartBoxMessage",
    method: POST,
    successMessage: "Successfully updated smart box successfully.",
    errorMessage: "Failed to update smart box details."
  },
  getSearchUserList: {
    endPoint: "agencyUser/driverManagment/searchUser?searchString=",
    method: POST
    // successMessage: "Fetched User list successfully.",
    // errorMessage: "Failed to fetch User list."
  },
  getSupplierrList: {
    endPoint: "agencyUser/driverManagment/getSupplierList",
    method: GET,
    // successMessage: "Fetched supplier list successfully.",
    errorMessage: "Failed to fetch User list."
  },
  deleteCandidateRating: {
    endPoint: "agencyUser/driverManagment/deleteCandidateRating?ratingObjectId=",
    method: POST,
    successMessage: "Deleted Rating successfully.",
    errorMessage: "Failed to delete rating."
  },
  deleteCandidateInfringements: {
    endPoint: "agencyUser/driverManagment/deleteCandidateInfringements?infringementObjectId=",
    method: POST,
    successMessage: "Deleted Infringement successfully.",
    errorMessage: "Failed to delete Infringement."
  },
  deleteCandidateNotes: {
    endPoint: "agencyUser/driverManagment/deleteNote?noteObjectId=",
    method: POST,
    successMessage: "Deleted Note successfully.",
    errorMessage: "Failed to delete Note."
  },
  deleteCandidateTickets: {
    endPoint: "agencyUser/driverManagment/deleteTicket?ticketObjectId=",
    method: POST,
    successMessage: "Deleted Ticket successfully.",
    errorMessage: "Failed to delete Ticket."
  },
  deleteCandidatePerformance: {
    endPoint: "agencyUser/driverManagment/deleteCandidatePerformance?performanceObjectId=",
    method: POST,
    successMessage: "Deleted Performance successfully.",
    errorMessage: "Failed to delete Performance."
  },
  updateCandidateTickets: {
    endPoint: "agencyUser/driverManagment/updateTickets?requestObject=",
    method: POST,
    successMessage: "Updated Ticket successfully.",
    errorMessage: "Failed to update Ticket."
  },
  addBookingRule: {
    endPoint: "agencyUser/siteManagment/getRequirementsEditorData",
    method: GET,
    // successMessage: "Fetched Book rules successfully",
    errorMessage: "Failed to fetch book rules"
  },
  saveAwrDetails: {
    endPoint: "agencyUser/siteManagment/addSiteAWRDetails?siteObjectId=",
    method: POST,
    successMessage: "Successfully added AWR Details",
    errorMessage: "Failed to add AWR Details"
  },
  saveBookingRule: {
    endPoint: "agencyUser/siteManagment/addSiteBookingRules?siteObjectId=",
    method: POST,
    successMessage: "Successfully added Booking Rules Details",
    errorMessage: "Failed to add Booking Rules"
  },
  defaultAWRDetails: {
    endPoint: "agencyUser/siteManagment/getSiteAWRDetails?siteObjectId=",
    method: GET,
    // successMessage: "Fetched AWR Details successfully",
    errorMessage: "Failed to Fetch AWR Details"
  },
  getTagsListDetails: {
    endPoint: "agencyUser/driverManagment/getTags",
    method: GET,
    // successMessage: "Fetched Tags list successfully",
    errorMessage: "Failed to Fetch Tags list"
  },
  getTagsListV2Details: {
    endPoint: "agencyUser/driverManagment/getTagsV2",
    method: GET,
    // successMessage: "Fetched Tags list successfully",
    errorMessage: "Failed to Fetch Tags list"
  },
  addTagsListDetails: {
    endPoint: "agencyUser/driverManagment/createTag",
    method: POST,
    successMessage: "Successfully added tag",
    errorMessage: "Failed to add tag"
  },
  updateSiteGeneralDetails: {
    endPoint: "agencyUser/siteManagment/updateSite",
    method: POST,
    successMessage: "Successfully Updated General Details",
    errorMessage: "Failed to Updated General Details"
  },
  addClientShiftTypeData: {
    endPoint: "agencyUser/siteManagment/addClientShiftTypeDetail",
    method: POST,
    successMessage: "Successfully Added Client Shift Type Detail",
    errorMessage: "Failed to Added Client Shift Type Detail"
  },
  updateClientShiftTypeDetail: {
    endPoint: "agencyUser/siteManagment/updateClientShiftTypeDetail",
    method: POST,
    successMessage: "Successfully Updated Client Shift Type Detail",
    errorMessage: "Failed to Updated Client Shift Type Detail"
  },
  deleteClientShiftTypeDetail: {
    endPoint: "agencyUser/siteManagment/deleteClientShiftTypeDetail",
    method: POST,
    successMessage: "Successfully Deleted Client Shift Type Detail",
    errorMessage: "Failed to Deleted Client Shift Type Detail"
  },
  updateContact: {
    endPoint: "agencyUser/driverManagment/updateContact?objectId=",
    method: POST,
    successMessage: "Successfully Updated Contact Details",
    errorMessage: "Failed to Updated Contact Details"
  },
  getGeneralDetails: {
    endPoint: "agencyUser/siteManagment/getSiteGeneralDetails?siteObjectId=",
    method: GET,
    // successMessage: "Fetch General Details Successfully",
    errorMessage: "Failed to Fetch General Details"
  },
  getCandidateDetails: {
    endPoint: "agencyUser/driverManagment/getCandidateGeneralDetails?personObjectId=",
    method: GET,
    // successMessage: "Fetched Candidate details successfully",
    errorMessage: "Failed to Fetch Candidate details"
  },
  updateCandidateDetails: {
    endPoint: "agencyUser/driverManagment/UpdateCandidatedetails",
    method: POST,
    successMessage: "Successfully updated Candidate details",
    errorMessage: "Failed to update Candidate details"
  },
  getContactDetails: {
    endPoint: "agencyUser/driverManagment/getContacts?objectId=",
    method: GET,
    // successMessage: "Fetched Candidate contact details successfully",
    errorMessage: "Failed to Fetch Candidate contact details"
  },
  updateContactDetails: {
    endPoint: "agencyUser/driverManagment/updateContact?objectId=",
    method: POST,
    successMessage: "Successfully updated contact details",
    errorMessage: "Failed to update contact details"
  },
  getAdditionalContacts: {
    endPoint: "agencyUser/driverManagment/getCandidateAdditionalDetails?personObjectId=",
    method: GET,
    // successMessage: "Fetched Candidate Additional contact details successfully",
    errorMessage: "Failed to Fetch Candidate Additional contact details"
  },
  updateAdditionalContacts: {
    endPoint: "agencyUser/driverManagment/updateCandidateAdditionalDetails?personObjectId=",
    method: POST,
    successMessage: "Successfully updated additional contact details",
    errorMessage: "Failed to update contact additional details"
  },
  getWorkHistoryList: {
    endPoint: "agencyUser/driverManagment/getWorkHistory?personObjectId=",
    method: GET,
    // successMessage: "Fetched Candidate work history successfully",
    errorMessage: "Failed to Fetch Candidate work history"
  },
  getStartEndDates: {
    endPoint: "agencyUser/driverManagment/getStartEndDates?candidateObjectId=",
    method: GET,
    // successMessage: "Fetched Candidate work history successfully",
    errorMessage: "Failed to Fetch Candidate start/end dates"
  },
  deleteAddressInfo: {
    endPoint: "agencyUser/driverManagment/deleteContact",
    method: POST,
    successMessage: "Deleted Address successfully.",
    errorMessage: "Failed to delete Address."
  },
  updateWorkHistory: {
    endPoint: "agencyUser/driverManagment/updateWorkHistory?requestObject=",
    method: POST,
    successMessage: "Successfully updated work history details",
    errorMessage: "Failed to update work history details"
  },
  getInductionAndBanDetails: {
    endPoint: "agencyUser/driverManagment/getInductionAndBanDetails?personObjectId=",
    method: GET,
    // successMessage: "Fetched Candidate inductions and bans list successfully",
    errorMessage: "Failed to Fetch Candidate inductions and bans list"
  },
  updateInductionAndBan: {
    endPoint: "agencyUser/driverManagment/updateInductionAndBanDetails?requestObject=",
    method: POST,
    successMessage: "Successfully updated inductions and bans details",
    errorMessage: "Failed to update inductions and bans details"
  },
  deleteInductionDetails: {
    endPoint: "agencyUser/driverManagment/deleteInductionDetails",
    method: POST,
    successMessage: "Deleted induction successfully.",
    errorMessage: "Failed to delete induction."
  },
  deleteBanDetails: {
    endPoint: "agencyUser/driverManagment/deleteBanDetails",
    method: POST,
    successMessage: "Deleted Ban successfully.",
    errorMessage: "Failed to delete Ban."
  },
  deleteAWRRule: {
    endPoint: "agencyUser/siteManagment/deleteSiteAWRDetails?awrDetailObjectId=",
    method: POST,
    successMessage: "Successfully deleted AWR rule",
    errorMessage: "Failed to delete AWR rule"
  },
  copyAWRRule: {
    endPoint: "agencyUser/siteManagment/copySiteAWRDetails",
    method: POST,
    successMessage: "Successfully copied AWR rule",
    errorMessage: "Failed to copy AWR rule"
  },
  getBookingRule: {
    endPoint: "agencyUser/siteManagment/getSiteBookingRules?siteObjectId=",
    method: GET,
    // successMessage: "Successfully fetch booking rule",
    errorMessage: "Failed to fetch booking rule"
  },
  getResourceRequirements: {
    endPoint: "agencyUser/siteManagment/getSiteResourceRequirements?siteObjectId=",
    method: GET,
    // successMessage: "Successfully fetched resource requirement",
    errorMessage: "Failed to fetch resource requirement"
  },
  saveResourceRequirements: {
    endPoint: "agencyUser/siteManagment/addSiteResourceRequirements?siteObjectId=",
    method: POST,
    successMessage: "Successfully saved resource requirements",
    errorMessage: "Failed to save resource requirement"
  },
  updateResourceRequirements: {
    endPoint: "agencyUser/siteManagment/updateSiteResourceRequirements?siteObjectId=",
    method: POST,
    successMessage: "Successfully updated resource requirements",
    errorMessage: "Failed to updated resource requirement"
  },
  deleteResourceRequirements: {
    endPoint: "agencyUser/siteManagment/deleteSiteResourceRequirements?siteObjectId=",
    method: POST,
    successMessage: "Resource requirements deleted successfully",
    errorMessage: "Failed to delete resource requirement"
  },
  pipelineCandidate: {
    endPoint: "agencyUser/siteManagment/getSitePipelineCandidates?siteObjectId=",
    method: GET,
    // successMessage: "Fetched pipeline candidate successfully",
    errorMessage: "Failed to fetch pieline candidate"
  },
  candidatePerformance: {
    endPoint: "agencyUser/siteManagment/getSitePerformanceItems?siteObjectId=",
    method: GET,
    // successMessage: "Fetched candidatePeformance successfully",
    errorMessage: "Failed to fetch candidatePerformace"
  },
  siteinfringements: {
    endPoint: "agencyUser/siteManagment/getSiteInfringementItems?siteObjectId=",
    method: GET,
    // successMessage: "Fetched Infringements successfully",
    errorMessage: "Failed to fetch Infringements"
  },
  getPersonTimePreference: {
    endPoint: "agencyUser/driverManagment/getPersonTimePreference?personObjectId=",
    method: GET,
    // successMessage: "Successfully fetched person time preference",
    errorMessage: "Failed to fetch person time preference"
  },
  getIsCandidateSts: {
    endPoint: "agencyUser/driverManagment/getIsCandidateSts?candidateObjectId=",
    method: GET,
    errorMessage: "Failed to fetch person details"
  },
  getPayrollInformation: {
    endPoint: "agencyUser/driverManagment/getPayrollInformation?candidateObjectId=",
    method: GET,
    // successMessage: "Successfully fetched person payroll details",
    errorMessage: "Failed to fetch person payroll details"
  },
  updatePayrollInformation: {
    endPoint: "agencyUser/driverManagment/updatePayrollInformation?candidateObjectId=",
    method: POST,
    successMessage: "Successfully Updated person payroll details",
    errorMessage: "Failed to update person payroll details"
  },
  addDrivingLicense: {
    endPoint: "agencyUser/driverManagment/DrivingLicense",
    method: POST,
    successMessage: "Successfully saved licence information",
    errorMessage: "Failed to save licence information"
  },
  getDrivingLicense: {
    endPoint: "agencyUser/driverManagment/getDrivingLicense",
    method: POST,
    // successMessage: "Successfully fetched licence information",
    errorMessage: "Failed to fetch licence information"
  },
  getHumanResource: {
    endPoint: "agencyUser/driverManagment/getHumanResourceDetails?personObjectId=",
    method: GET,
    // successMessage: "Successfully fetched human resource information",
    errorMessage: "Failed to fetch human resource information"
  },
  updateHumanResource: {
    endPoint: "agencyUser/driverManagment/updateHumanResourceDetails?personObjectId=",
    method: POST,
    successMessage: "Successfully updated human resource information",
    errorMessage: "Failed to fetch update resource information"
  },
  addLicenceCapabilities: {
    endPoint: "agencyUser/driverManagment/addLicenceCapabilities?requestObject=",
    method: POST,
    successMessage: "Successfully Saved capability information",
    errorMessage: "Failed to save capability information"
  },
  updateLicenceCapabilities: {
    endPoint: "agencyUser/driverManagment/updateLicenceCapabilities?requestObject=",
    method: POST,
    successMessage: "Successfully updated capability information",
    errorMessage: "Failed to update capability information"
  },
  addDrivingLicenseEndorsement: {
    endPoint: "agencyUser/driverManagment/addDrivingLicenseEndorsement",
    method: POST,
    successMessage: "Successfully saved endorsement information",
    errorMessage: "Failed to save endorsement information"
  },
  updateDrivingLicenseEndorsement: {
    endPoint: "agencyUser/driverManagment/updateDrivingLicenseEndorsement",
    method: POST,
    successMessage: "Successfully updated endorsement information",
    errorMessage: "Failed to update endorsement information"
  },
  deleteDrivingLicenseEndorsement: {
    endPoint: "agencyUser/driverManagment/deleteDrivingLicenseEndorsement?EndorsementObjectID=",
    method: POST,
    successMessage: "Successfully deleted endorsement information",
    errorMessage: "Failed to delete endorsement information"
  },
  deleteWorkHistory: {
    endPoint: "agencyUser/driverManagment/deleteWorkHistory?personObjectId=",
    method: POST,
    successMessage: "Successfully deleted work history information",
    errorMessage: "Failed to delete work history information"
  },
  deleteStartEndDate: {
    endPoint: "agencyUser/driverManagment/deleteStartEndDate",
    method: POST,
    successMessage: "Successfully deleted start/end date",
    errorMessage: "Failed to delete start/end date"
  },
  updateStartEndDate: {
    endPoint: "agencyUser/driverManagment/updateStartEndDate",
    method: POST,
    successMessage: "Successfully updated start/end date",
    errorMessage: "Failed to update start/end date"
  },
  deleteDrivingLicenseCapabilities: {
    endPoint: "agencyUser/driverManagment/deleteLicenceCapabilities",
    method: POST,
    successMessage: "Successfully deleted capability information",
    errorMessage: "Failed to delete capability information"
  },
  addDigiCard: {
    endPoint: "agencyUser/driverManagment/addDigiCard?requestObject=",
    method: POST,
    successMessage: "Successfully saved digi card information",
    errorMessage: "Failed to save digi card information"
  },
  costAnalysis: {
    endPoint: "clientUser/reportManagement/reportCostAnalysis",
    method: GET,
    // successMessage: "Report generated Successfully",
    errorMessage: "Failed to Generate the Report"
  },
  KPIReport: {
    endPoint: "clientUser/reportManagement/reportClientKPI",
    method: GET,
    // successMessage: "Report generated Successfully",
    errorMessage: "Failed to Generate the Report"
  },
  deleteDigiCard: {
    endPoint: "agencyUser/driverManagment/deleteDigiCard",
    method: POST,
    successMessage: "Successfully deleted digi card information",
    errorMessage: "Failed to delete digi card information"
  },
  updateDigiCard: {
    endPoint: "agencyUser/driverManagment/updateDigiCard?requestObject=",
    method: POST,
    successMessage: "Successfully saved digi card information",
    errorMessage: "Failed to save digi card information"
  },
  getDigiCard: {
    endPoint: "agencyUser/driverManagment/getDigiCardForPerson",
    method: POST,
    // successMessage: "Successfully fetched digi card information",
    errorMessage: "Failed to fetch digi card information"
  },
  getQualificationIdentification: {
    endPoint: "agencyUser/driverManagment/getQualificationIdentificationForPerson",
    method: POST,
    // successMessage: "Successfully fetched qualification information",
    errorMessage: "Failed to fetch qualification information"
  },
  deleteQualificationIdentification: {
    endPoint: "agencyUser/driverManagment/DeleteQualificationsAndIdentifications?requestObject=",
    method: POST,
    successMessage: "Successfully deleted Qualification information",
    errorMessage: "Failed to delete Qualification information"
  },
  updateQualificationIdentification: {
    endPoint: "agencyUser/driverManagment/UpdateQualificationsAndIdentifications?requestObject=",
    method: POST,
    successMessage: "Successfully updated Qualification information",
    errorMessage: "Failed to update Qualification information"
  },
  getBookingExpenseTypeList: {
    endPoint: "agencyUser/driverManagment/getBookingExpenseTypeList",
    method: GET,
    // successMessage: "Successfully fetched ExpenseTypeList information",
    errorMessage: "Failed to fetch ExpenseTypeList information"
  },
  addBookingExpenses: {
    endPoint: "agencyUser/bookingCalendar/addBookingExpenses",
    method: POST,
    successMessage: "Successfully Saved Booking Expenses Information",
    errorMessage: "Failed to save Booking Expenses Information"
  },
  updateBookingExpenses: {
    endPoint: "agencyUser/bookingCalendar/updateBookingExpenses",
    method: POST,
    successMessage: "Successfully Update Booking Expenses Information",
    errorMessage: "Failed to update Booking Expenses Information"
  },
  deleteBookingExpenses: {
    endPoint: "agencyUser/bookingCalendar/deleteBookingExpenses?bookingExpenseObjectId=",
    method: POST,
    successMessage: "Successfully Deleted Booking Expenses Information",
    errorMessage: "Failed to delete Booking Expenses Information"
  },
  clientInfringement: {
    endPoint: "clientUser/candidateManagement/getInfringementsList?siteObjectId=",
    method: GET,
    // successMessage: "Fetched Infringements type list successfully",
    errorMessage: "Failed to fetch Infringements type list"
  },
  clientPerformance: {
    endPoint: "clientUser/candidateManagement/getPerformanceList?siteObjectId=",
    method: GET,
    // successMessage: "Fetched candidatePeformance successfully",
    errorMessage: "Failed to fetch candidatePerformace"
  },
  verifiedBookings: {
    endPoint: `agencyUser/bookingCalendar/${
      isClientUser() ? "verifyBookingsClientUser" : "verifyBookings"
    }`,
    method: POST,
    successMessage: "Successfully verified bookings",
    errorMessage: "Failed to verify bookings"
  },
  unverifiedBookings: {
    endPoint: `agencyUser/bookingCalendar/${
      isClientUser() ? "unverifyBookingsClientUser" : "unverifyBookings"
    }`,
    method: POST,
    successMessage: "Successfully unverify bookings",
    errorMessage: "Failed to unverify bookings"
  },
  addBookingInfringements: {
    endPoint: "agencyUser/bookingCalendar/addBookingInfringements?requestObject=",
    method: POST,
    successMessage: "Successfully Saved Booking Infringement Information",
    errorMessage: "Failed to save Booking Infringement Information"
  },
  updateBookingInfringements: {
    endPoint: "agencyUser/bookingCalendar/updateBookingInfringements?requestObject=",
    method: POST,
    successMessage: "Successfully Update Booking Infringement Information",
    errorMessage: "Failed to update Booking Infringement Information"
  },
  clientInfringementSign: {
    endPoint: "clientUser/candidateManagement/setInfringementStatus?status=",
    method: POST,
    successMessage: "Successfully Update  Infringement status",
    errorMessage: "Failed to update Infringement status"
  },
  clientPerformanceClose: {
    endPoint: "clientUser/candidateManagement/setPerformanceStatus?status=",
    method: POST,
    successMessage: "Successfully Update  Performance status",
    errorMessage: "Failed to update  Performance status"
  },
  getRemindersList: {
    endPoint: "agencyUser/driverManagment/notesCheckForReminders?UpToDate=",
    method: GET,
    // successMessage: "Fetched Reminders list successfully",
    errorMessage: "Failed to fetch Reminders list"
  },
  deleteReminder: {
    endPoint: "agencyUser/driverManagment/notesDismissReminder?ReminderObjectId=",
    method: DELETE,
    successMessage: "Dismissed Reminder successfully.",
    errorMessage: "Failed to Dismiss Reminder."
  },
  getAssignedTickets: {
    endPoint: "agencyUser/driverManagment/getAssignedTickets?UserObjectId=",
    method: GET,
    // successMessage: "Fetched Tickets list successfully",
    errorMessage: "Failed to fetch Tickets list"
  },
  pendingDocument: {
    endPoint: "financeUser/payrollManagement/getPendingItemsList",
    method: GET,
    // successMessage: "Fetched Pending Document list successfully.",
    errorMessage: "Failed to fetch Pending Document list."
  },
  payDocument: {
    endPoint: "financeUser/payrollManagement/listPayDocuments",
    method: POST,
    successMessage: "Fetched Pay Document list successfully.",
    errorMessage: "Failed to fetch Pay Document list."
  },
  holdPendingDocument: {
    endPoint: "financeUser/payrollManagement/holdItem?objectId=",
    method: POST,
    successMessage: "Document Holded successfully.",
    errorMessage: "Failed to Hold Document."
  },
  unHoldPendingDocument: {
    endPoint: "financeUser/payrollManagement/releasItem?objectId=",
    method: POST,
    successMessage: "Document Un Holded successfully.",
    errorMessage: "Failed to Un Hold  Document."
  },
  createPayDocument: {
    endPoint: "financeUser/payrollManagement/createPayDocument",
    method: POST,
    successMessage: "Pay Document Created Successfully",
    errorMessage: "Failed to create Pay Document"
  },
  sendToXero: {
    endPoint: "financeUser/payrollManagement/sendToXero",
    method: POST,
    successMessage: "sent To Xero successfully",
    errorMessage: "Failed to sent To Xero"
  },
  modifyPayDocument: {
    endPoint: "financeUser/payrollManagement/modifyPayDocuments",
    method: POST,
    successMessage: "Pay Document Modified Successfully",
    errorMessage: "Failed to Modify Pay Document"
  },
  viewPayDocument: {
    endPoint: "financeUser/payrollManagement/viewPayDocument?payDocumentObjectId=",
    method: POST,
    successMessage: "Fetched View Document Information Successfully",
    errorMessage: "Failed to Fetch View Document"
  },
  deleteMultipleHolidays: {
    endPoint: "financeUser/payrollManagement/deleteMultipleHolidays",
    method: POST,
    // successMessage: "Fetched Pay Rate list successfully",
    errorMessage: "Failed to delete Holidays"
  },
  getHolidayListing: {
    endPoint: "financeUser/payrollManagement/getListHolidays",
    method: POST,
    // successMessage: "Fetched Pay Rate list successfully",
    errorMessage: "Failed to Fetch Holidays"
  },
  payRateListing: {
    endPoint: "financeUser/payrollManagement/listPayRates",
    method: POST,
    // successMessage: "Fetched Pay Rate list successfully",
    errorMessage: "Failed to Fetch Pay Rate"
  },
  addAdjustment: {
    endPoint: "financeUser/payrollManagement/addAdjustment",
    method: POST,
    successMessage: "Adjustment Add successfully",
    errorMessage: "Failed to Add Adjustment"
  },
  updatePayDocument: {
    endPoint: "financeUser/payrollManagement/updatePayDocument",
    method: POST,
    successMessage: "Pay Document saved successfully",
    errorMessage: "Failed to update Pay Document"
  },
  updateAdjustment: {
    endPoint: "financeUser/payrollManagement/updateAdjustment",
    method: POST,
    successMessage: "Adjustment Updated successfully",
    errorMessage: "Failed to Update Adjustment"
  },
  deleteAdjustment: {
    endPoint: "financeUser/payrollManagement/deleteAdjustment?adjustmentObjectId=",
    method: POST,
    successMessage: "Adjustment delete successfully",
    errorMessage: "Failed to delete Adjustment"
  },
  printPayDocument: {
    endPoint: "financeUser/payrollManagement/printPayDocument",
    method: POST,
    successMessage: "Pay Document Download successfully",
    errorMessage: "Failed to Download Pay Document"
  },
  deletePayRate: {
    endPoint: "financeUser/payrollManagement/deletePayRate?payRateObjectId=",
    method: POST,
    successMessage: "Pay Rates delete successfully",
    errorMessage: "Failed to delete Pay Rate Document"
  },
  updateBookingClientDetails: {
    endPoint: "agencyUser/bookingCalendar/updateBookingClientDetails?bookingObjectId=",
    method: POST,
    successMessage: "Successfully Update Booking Details Information",
    errorMessage: "Failed to update Booking Details Information"
  },
  updateBookingDetails: {
    endPoint: "agencyUser/bookingCalendar/updateBookingDetails?bookingObjectId=",
    method: POST,
    successMessage: "Successfully Update Booking Details Information",
    errorMessage: "Failed to update Booking Details Information"
  },
  updateBasicBookingDetails: {
    endPoint: "/agencyUser/bookingCalendar/updateBasicBookingDetails?bookingObjectId=",
    method: POST,
    successMessage: "Successfully Update Booking Details Information",
    errorMessage: "Failed to update Booking Details Information"
  },
  changeBookingAWRScope: {
    endPoint: "agencyUser/bookingCalendar/changeBookingAWRScope?bookingObjectId=",
    method: POST,
    successMessage: "Successfully Saved Booking AWR Information",
    errorMessage: "Failed to Saved Booking AWR Information"
  },
  addContractTypes: {
    endPoint: "systemAdmin/systemUserManagement/addNewContractType",
    method: POST,
    successMessage: "Successfully Saved Contract Type",
    errorMessage: "Failed to Save Contract Type"
  },
  updateContractType: {
    endPoint: "systemAdmin/systemUserManagement/updateContractType",
    method: POST,
    successMessage: "Successfully Updated the contract",
    errorMessage: "Failed to Update The Contract Type"
  },
  deleteContractType: {
    endPoint: "systemAdmin/systemUserManagement/deleteContractType",
    method: POST,
    successMessage: "Successfully Deleted Contract Type",
    errorMessage: "Failed to Delete Contract Type"
  },

  getQualificationTitleList: {
    endPoint: "agencyUser/driverManagment/getQualificationTitleList",
    method: GET,
    // successMessage: "Successfully Fetched Qualification Titles",
    errorMessage: "Failed to fetch Qualification Titles"
  },

  addQualifications: {
    endPoint: "systemAdmin/systemUserManagement/addNewQualification",
    method: POST,
    successMessage: "Successfully Added the Qualification",
    errorMessage: "Failed to add Qualification"
  },
  updateQualification: {
    endPoint: "systemAdmin/systemUserManagement/updateQualification",
    method: POST,
    successMessage: "Successfully Update Qualifications",
    errorMessage: "Failed to Delete Qualification"
  },
  deleteQualification: {
    endPoint: "systemAdmin/systemUserManagement/deleteQualification",
    method: POST,
    successMessage: "Successfully Deleted the Qualification",
    errorMessage: "Failed to Delete the Qualification"
  },
  getUserListBookingSetup: {
    endPoint: "systemAdmin/systemUserManagement/getUserListing",
    method: GET,
    // successMessage: "Successfully fetched the List",
    errorMessage: "Failed to fetch List"
  },
  getContractTypeList: {
    endPoint: "agencyUser/driverManagment/getContractTypeList",
    method: GET,
    // successMessage: "Successfully fetched the Contract Type List",
    errorMessage: "Failed to fecth Contract Type List"
  },
  getUserGroupList: {
    endPoint: "systemAdmin/systemUserManagement/getUserGroupList",
    method: GET,
    // successMessage: "Successfully fetched User Groups",
    errorMessage: "Failed to fetch User Group List"
  },
  addpayRateRules: {
    endPoint: "financeUser/payrollManagement/createPayRate",
    method: POST,
    successMessage: "Pay Rate Rules Created Successfully",
    errorMessage: "Failed to create Pay Rate Rules"
  },
  addpayRateCard: {
    endPoint: "financeUser/payrollManagement/createRateCard",
    method: POST,
    successMessage: "Pay Rate Card Created Successfully",
    errorMessage: "Failed to create Pay Rate Card"
  },
  updatePayRateRules: {
    endPoint: "financeUser/payrollManagement/updatePayRate",
    method: POST,
    successMessage: "Pay Rate Rules updated Successfully",
    errorMessage: "Failed to update Pay Rate Rules"
  },
  UpdatepayRateCard: {
    endPoint: "financeUser/payrollManagement/updateRateCard",
    method: POST,
    successMessage: "Pay Rate Card updated Successfully",
    errorMessage: "Failed to updated Pay Rate Card"
  },
  deletePayRateCard: {
    endPoint: "financeUser/payrollManagement/deleteRateCard?rateCardObjectId=",
    method: POST,
    successMessage: "Pay Rate Card Deleted Successfully",
    errorMessage: "Failed to delete Pay Rate Card"
  },
  copyPayRate: {
    endPoint: "financeUser/payrollManagement/copyPayRate?sourcePayRateObjectId=",
    method: POST,
    successMessage: "Pay Rate Copied Successfully",
    errorMessage: "Failed to Copy the pay rate"
  },
  viewPayRateHistory: {
    endPoint: "financeUser/payrollManagement/viewPayRateHistory?payRateObjectId=",
    method: GET,
    // successMessage: "Successfully fetched pay rate history",
    errorMessage: "Failed to fetch pay rate history"
  },
  getBuildProgress: {
    endPoint: "financeUser/payrollManagement/getBuildProgress?jobObjectId=",
    method: GET,
    // successMessage: "Successfully fetched documents progress",
    errorMessage: "Failed to fetch documents progress"
  },
  ViewpayRateCard: {
    endPoint: "financeUser/payrollManagement/viewPayRate?payRateObjectId=",
    method: GET,
    // successMessage: "Fetched Pay Rate Information successfully",
    errorMessage: "Failed to Fetched Pay Rate Information"
  },
  buildPayDocuments: {
    endPoint: "financeUser/payrollManagement/buildPayDocuments",
    method: POST,
    successMessage: "Successfully generated documents",
    errorMessage: "Failed to generate documents"
  },
  deleteUserGroup: {
    endPoint: "systemAdmin/systemUserManagement/deleteUserGroup",
    method: POST,
    successMessage: "Successfully deleted the User Group",
    errorMessage: "Failed to delete the User Group"
  },
  addNewUserGroup: {
    endPoint: "systemAdmin/systemUserManagement/addNewUserGroup",
    method: POST,
    successMessage: "Successfully Added the User Group",
    errorMessage: "Failed to Add the User Group"
  },
  getBookingExpenseTypes: {
    endPoint: "systemAdmin/systemUserManagement/getBookingExpenseTypes",
    method: GET,
    // successMessage: "Successfully fetched the expense types",
    errorMessage: "Failed to fetch the expense types"
  },
  addBookingExpenseType: {
    endPoint: "systemAdmin/systemUserManagement/addBookingExpenseType",
    method: POST,
    successMessage: "Successfully added the Expense Booking",
    errorMessage: "Failed to add Expense Booking"
  },
  deleteBookingExpenseType: {
    endPoint: "systemAdmin/systemUserManagement/deleteBookingExpenseType",
    method: POST,
    successMessage: "Successfully Deleted the Expense Type Booking",
    errorMessage: "Failed to delete the Expense Type Booking"
  },
  getTags: {
    endPoint: "agencyUser/driverManagment/getTags",
    method: GET,
    // successMessage: "Successfully Fetched the Tags ",
    errorMessage: "Failed To fetch the Tags"
  },

  deleteTag: {
    endPoint: "systemAdmin/systemUserManagement/deleteTag",
    method: POST,
    successMessage: "Successfully Deleted the Tag",
    errorMessage: "Failed to Delete the Tag"
  },

  createTag: {
    endPoint: "agencyUser/driverManagment/createTag",
    method: POST,
    successMessage: "Successfully Created Tag",
    errorMessage: "Failed to create Tag"
  },

  updateTag: {
    endPoint: "systemAdmin/systemUserManagement/updateTag",
    method: POST,
    successMessage: "Successfully Updated Tag",
    errorMessage: "Failed to Update Tag"
  },

  deleteCandidateRatingType: {
    endPoint: "systemAdmin/systemUserManagement/deleteCandidateRatingType",
    method: POST,
    successMessage: "Successfully Deleted the CandidateRatingType",
    errorMessage: "Failed to Delete the CandidateRatingType"
  },

  addCandidateRatingType: {
    endPoint: "systemAdmin/systemUserManagement/addCandidateRatingType",
    method: POST,
    successMessage: "Successfully Added the Candidate Rating Type",
    errorMessage: "Failed Add the Candidate Rating Type"
  },

  updateCandidateRatingType: {
    endPoint: "systemAdmin/systemUserManagement/updateCandidateRatingType",
    method: POST,
    successMessage: "Successfully Updated the Candidate Rating Type",
    errorMessage: "Failed to update the Candidate Rating Type"
  },

  updateBookingExpenseType: {
    endPoint: "systemAdmin/systemUserManagement/updateBookingExpenseType",
    method: POST,
    successMessage: "Successfully updated the Expenses",
    errorMessage: "Failed to update the Expense"
  },

  updateUserGroup: {
    endPoint: "systemAdmin/systemUserManagement/updateUserGroup",
    method: POST,
    successMessage: "Successfully updated the Groups",
    errorMessage: "Failed to update the Groups"
  },

  getCandidateInfringementTypeList: {
    endPoint: "agencyUser/driverManagment/getCandidateInfringementTypeList",
    method: GET,
    // successMessage: "Successfully Fetched the Infringement List",
    errorMessage: "Failed to Fetch the Infringement List"
  },

  deleteCandidateInfringementType: {
    endPoint: "systemAdmin/systemUserManagement/deleteCandidateInfringementType",
    method: POST,
    successMessage: "Successfully Deleted the Candidate InfringementType",
    errorMessage: "Failed to Delete the Candidate InfringementType"
  },

  addNewCandidateInfringementType: {
    endPoint: "systemAdmin/systemUserManagement/addNewCandidateInfringementType",
    method: POST,
    successMessage: "Successfully Added the Infringement type",
    errorMessage: "Failed Add the Candidate Infringement Type"
  },

  updateCandidateInfringementType: {
    endPoint: "systemAdmin/systemUserManagement/updateCandidateInfringementType",
    method: POST,
    successMessage: "Successfully Updated the Infringement type",
    errorMessage: "Failed update the Candidate Infringement Type"
  },
  getUserClientList: {
    endPoint: "agencyUser/driverManagment/getUserClientList",
    method: GET,
    // successMessage: "Fetched client list successfully",
    errorMessage: "Failed to fetch client list"
  },
  getVerfiyBooking: {
    endPoint: "financeUser/payrollManagement/getBookings",
    method: POST,
    successMessage: "Fetched Booking list successfully",
    errorMessage: "Failed to fetch Booking list"
  },
  supplierList: {
    endPoint: "agencyUser/driverManagment/sm_Supplier",
    method: GET,
    // successMessage: "Successfully fetched supplier list",
    errorMessage: "Failed to fetch supplier list"
  },
  addSupplier: {
    endPoint: "agencyUser/driverManagment/add_sm_Supplier",
    method: POST,
    successMessage: "Successfully added supplier details",
    errorMessage: "Failed to add supplier details"
  },
  updateSupplier: {
    endPoint: "agencyUser/driverManagment/update_sm_Supplier",
    method: POST,
    successMessage: "Successfully Updated supplier details",
    errorMessage: "Failed to Update supplier details"
  },
  getSupplierEmailTemplate: {
    endPoint: "agencyUser/driverManagment/getBookingTemplateSupplierEmail",
    method: POST,
    successMessage: "Successfully get supplier email",
    errorMessage: "Failed to get supplier email"
  },
  deleteSupplier: {
    endPoint: "agencyUser/driverManagment/delete_sm_Supplier",
    method: POST,
    successMessage: "Successfully Deleted supplier",
    errorMessage: "Failed to Delete supplier details"
  },
  addStsCandidate: {
    endPoint: "agencyUser/driverManagment/add_sts_candidate",
    method: POST,
    successMessage: "Successfully added candidate details",
    errorMessage: "Failed to add candidate details"
  },
  saveSupplierClientApproval: {
    endPoint: "agencyUser/driverManagment/setSupplierClientApprovals",
    method: POST,
    successMessage: "",
    errorMessage: ""
  },
  sendSupplierEmail: {
    endPoint: "agencyUser/driverManagment/sendSupplierEmail",
    method: POST,
    successMessage: "",
    errorMessage: ""
  },
  getListSupplierCandidatesPaged: {
    endPoint: "agencyUser/driverManagment/getListSupplierCandidatesPaged",
    method: POST,
    successMessage: "Successfully get candidate list",
    errorMessage: "Failed to get candidate list"
  },
  getCandidatePerformanceTypeList: {
    endPoint: "agencyUser/driverManagment/getCandidatePerformanceTypesList",
    method: GET,
    // successMessage: "Successfully Fetched the Performance List",
    errorMessage: "Failed to Fetch the Performance List"
  },

  deleteCandidatePerformanceType: {
    endPoint: "systemAdmin/systemUserManagement/deleteCandidatePerformanceType",
    method: POST,
    successMessage: "Successfully Deleted the Performance List",
    errorMessage: "Failed to Delete the Performance List"
  },

  addCandidatePerformanceType: {
    endPoint: "systemAdmin/systemUserManagement/addCandidatePerformanceType",
    method: POST,
    successMessage: "Successfully Added the Performance List",
    errorMessage: "Failed to add the Performance List"
  },

  updateCandidatePerformanceType: {
    endPoint: "systemAdmin/systemUserManagement/updateCandidatePerformanceType",
    method: POST,
    successMessage: "Successfully Updated the Performance List",
    errorMessage: "Failed to update the Performance List"
  },

  getNonConversionReasonList: {
    endPoint: "agencyUser/driverManagment/getNonConversionReasonList",
    method: GET,
    // successMessage: "Successfully Fetched the Non Conversion List",
    errorMessage: "Failed to Fetch the Performance List"
  },

  deleteInsuranceCategoryType: {
    endPoint: "systemAdmin/systemUserManagement/deleteInsuranceCategoryType",
    method: POST,
    successMessage: "Successfully Deleted the Insurance Category",
    errorMessage: "Failed to delete the Insurance Category"
  },

  addInsuranceCategoryType: {
    endPoint: "systemAdmin/systemUserManagement/addInsuranceCategoryType",
    method: POST,
    successMessage: "Successfully Added the Insurance Category List",
    errorMessage: "Failed to add the Insurance Category List"
  },

  updateInsuranceCategoryType: {
    endPoint: "systemAdmin/systemUserManagement/updateInsuranceCategoryType",
    method: POST,
    successMessage: "Successfully Update the Insurance Category List",
    errorMessage: "Failed to update the Insurance Category List"
  },
  getSiteDocument: {
    endPoint: "agencyUser/siteManagment/getSiteDocuments?siteObjectId=",
    method: GET,
    // successMessage: "Successfully Fetched Site Document",
    errorMessage: "Failed to Fetch Site Document"
  },
  deleteSiteDocument: {
    endPoint: "agencyUser/siteManagment/deleteSiteDocuments?documentObjectId=",
    method: POST,
    successMessage: "Document Deleted Successfully",
    errorMessage: "Failed to Delete  Document"
  },
  addNonConversionReasonType: {
    endPoint: "systemAdmin/systemUserManagement/addNonConversionReasonType",
    method: POST,
    successMessage: "Successfully Added the Non Conversion Type",
    errorMessage: "Failed to add the Non Conversion Type"
  },

  updateNonConversionReasonType: {
    endPoint: "systemAdmin/systemUserManagement/updateNonConversionReasonType",
    method: POST,
    successMessage: "Successfully Update the Non Conversion Reason List",
    errorMessage: "Failed to update the Non Conversion Reason List"
  },

  deleteNonConversionReasonType: {
    endPoint: "systemAdmin/systemUserManagement/deleteNonConversionReasonType",
    method: POST,
    successMessage: "Successfully Deleted the Non Conversion Reason",
    errorMessage: "Failed to delete the Non Conversion Reason"
  },

  addLeadSourceType: {
    endPoint: "systemAdmin/systemUserManagement/addLeadSourceType",
    method: POST,
    successMessage: "Successfully Add the Lead Source Types",
    errorMessage: "Failed to Add the lead source types"
  },

  updateLeadSourceType: {
    endPoint: "systemAdmin/systemUserManagement/updateLeadSourceType",
    method: POST,
    successMessage: "Successfully Updated the Lead Source Types",
    errorMessage: "Failed to Update the Lead Source Types"
  },

  deleteLeadSourceType: {
    endPoint: "systemAdmin/systemUserManagement/deleteLeadSourceType",
    method: POST,
    successMessage: "Successfully Deleted the Lead Source Types",
    errorMessage: "Failed to Delete the Lead Source Types"
  },

  addLeavingReasonType: {
    endPoint: "systemAdmin/systemUserManagement/addLeavingReasonType",
    method: POST,
    successMessage: "Successfully Add the Lead Source Types",
    errorMessage: "Failed to Add the lead source types"
  },

  updateLeavingReasonType: {
    endPoint: "systemAdmin/systemUserManagement/updateLeavingReasonType",
    method: POST,
    successMessage: "Successfully Updated the Leaving Reasons Types",
    errorMessage: "Failed to Update the Leaving Reasons Types"
  },

  deleteLeavingReasonType: {
    endPoint: "systemAdmin/systemUserManagement/deleteLeavingReasonType",
    method: POST,
    successMessage: "Successfully Deleted the Leaving Reasons Types",
    errorMessage: "Failed to Delete the Leaving Reasons Types"
  },
  // BANK HOLIDAY SYSTEM ADMIN
  deleteBankHoliday: {
    endPoint: "systemAdmin/systemUserManagement/deleteBankHoliday",
    method: POST,
    successMessage: "Successfully Deleted the Bank Holiday",
    errorMessage: "Failed to delete The Bank Holiday"
  },

  updateBankHoliday: {
    endPoint: "systemAdmin/systemUserManagement/updateBankHoliday",
    method: POST,
    successMessage: "Successfully Updated the Bank Holiday",
    errorMessage: "Failed to Update the Bank holiday"
  },

  addBankHoliday: {
    endPoint: "systemAdmin/systemUserManagement/addBankHoliday",
    method: POST,
    successMessage: "Successfully Add the Bank Holidays",
    errorMessage: "Failed to Add the Bank Holidays"
  },

  getBankHolidayList: {
    endPoint: "systemAdmin/systemUserManagement/getBankHolidayList",
    method: GET,
    // successMessage: "Successfully fetched the Bank Holidays",
    errorMessage: "Failed to fetch the Bank Holidays"
  },
  getPayeStatementList: {
    endPoint: "agencyUser/driverManagment/getPayeStatementList",
    method: GET,
    // successMessage: "Successfully fetched the statement list",
    errorMessage: "Failed to fetch the statement list"
  },
  deleteUserTags: {
    endPoint: "agencyUser/driverManagment/removeTag?TargetObjectIds=",
    method: DELETE,
    successMessage: "Successfully deleted tag information",
    errorMessage: "Failed to delete tag information"
  },
  getBookingType: {
    endPoint: "systemAdmin/systemUserManagement/getBookingTypeList",
    method: GET,
    // successMessage: "Successfully fetched the Booking Type list",
    errorMessage: "Failed to fetch the Booking Type list"
  },
  addBookingType: {
    endPoint: "systemAdmin/systemUserManagement/addNewBookingType",
    method: POST,
    successMessage: "Successfully Added Booking Type",
    errorMessage: "Failed to Add Booking Type"
  },
  updateBookingType: {
    endPoint: "systemAdmin/systemUserManagement/updateBookingType",
    method: POST,
    successMessage: "Successfully Updated Booking Type",
    errorMessage: "Failed to Update Booking Type"
  },
  listAttachments: {
    endPoint: "agencyUser/driverManagment/listAttachments",
    method: POST,
    successMessage: "Successfully fetched attachments list",
    errorMessage: "Failed to fetch attachments list"
  },
  downloadDocument: {
    endPoint: "agencyUser/driverManagment/downloadDocument",
    method: POST,
    successMessage: "Successfully downloaded document",
    errorMessage: "Failed to download document"
  },
  deleteMultiDocument: {
    endPoint: "agencyUser/driverManagment/deleteMultiDocument",
    method: POST,
    successMessage: "Successfully deleted document",
    errorMessage: "Failed to delete document"
  },
  deleteBookingType: {
    endPoint: "systemAdmin/systemUserManagement/deleteBookingType?",
    method: POST,
    successMessage: "Successfully deleted Booking Type",
    errorMessage: "Failed to delete Booking Type"
  },
  addNewIdentification: {
    endPoint: "systemAdmin/systemUserManagement/addNewIdentification",
    method: POST,
    successMessage: "Successfully Added Identification Type",
    errorMessage: "Failed to Add Identification Type"
  },
  updateIdentification: {
    endPoint: "systemAdmin/systemUserManagement/updateIdentification",
    method: POST,
    successMessage: "Successfully Updated Identification Type",
    errorMessage: "Failed to Update Identification Type"
  },

  deleteIdentification: {
    endPoint: "systemAdmin/systemUserManagement/deleteIdentification?",
    method: POST,
    successMessage: "Successfully deleted Identification Type",
    errorMessage: "Failed to delete Identification Type"
  },
  deleteSmartBoxMessage: {
    endPoint: "agencyUser/driverManagment/deleteSmartBoxMessage?candidateObjectId=",
    method: POST,
    successMessage: "Successfully deleted smartBox message",
    errorMessage: "Failed to delete smartBox message"
  },
  updateSiteInfringement: {
    endPoint: "agencyUser/siteManagment/updateSiteInfringementItems?requestObject=",
    method: POST,
    successMessage: "Successfully Updated Infringement Information",
    errorMessage: "Failed to Updated Infringement Information"
  },

  getPhoneNumbersList: {
    endPoint: "/agencyUser/driverManagment/getPhoneNumbersList?objectId=",
    method: GET,
    // successMessage: "Successfully fetched the Phone Number list",
    errorMessage: "Failed to fetch the Phone Number list"
  },

  updateVerifyBookingDetails: {
    endPoint: "financeUser/payrollManagement/updateBookingFields",
    method: POST,
    successMessage: "Successfully Updated Booking Details",
    errorMessage: "Failed to Updated Booking Details"
  },
  refreshMetaCache: {
    endPoint: "baseModule/userManagement/refreshMetaCache",
    method: GET,
    successMessage: "Successfully refreshed meta cache information",
    errorMessage: "Failed to refresh meta cache information"
  },
  addClient: {
    endPoint: "agencyAdmin/clientManagment/addClient?clientName=",
    method: POST,
    successMessage: "Successfully added client information",
    errorMessage: "Failed to add client information"
  },
  updateClient: {
    endPoint: "agencyAdmin/clientManagment/updateClient?clientName=",
    method: POST,
    successMessage: "Successfully updated client information",
    errorMessage: "Failed to update client information"
  },
  deleteClient: {
    endPoint: "agencyAdmin/clientManagment/deleteClient?clientObjectId=",
    method: POST,
    successMessage: "Successfully deleted client information",
    errorMessage: "Failed to delete client information"
  },
  getClientDocumentTypeList: {
    endPoint: "systemAdmin/systemUserManagement/getClientDocumentTypeList",
    method: GET,
    // successMessage: "Successfully Fetched Client Documents",
    errorMessage: "Failed to fetch Client Documents"
  },
  addClientDocumentType: {
    endPoint: "systemAdmin/systemUserManagement/addClientDocumentType",
    method: POST,
    successMessage: "Successfully Added the Client Documents",
    errorMessage: "Failed to add the Client Documents"
  },
  updateClientDocumentType: {
    endPoint: "systemAdmin/systemUserManagement/updateClientDocumentType",
    method: POST,
    successMessage: "Successfully Updated the Client Document",
    errorMessage: "Failed to Update the Client Document"
  },
  deleteClientDocumentType: {
    endPoint: "systemAdmin/systemUserManagement/deleteClientDocumentType",
    method: POST,
    successMessage: "Successfully Deleted the Client Document",
    errorMessage: "Failed to delete the Client Document"
  },
  getObjectPermissions: {
    endPoint: "systemAdmin/systemUserManagement/getObjectPermissions",
    method: GET,
    // successMessage: "Successfully fetched the Sites of User",
    errorMessage: "Failed to fetch the User Sites"
  },
  addServiceCompany: {
    endPoint: "systemAdmin/systemUserManagement/addServiceCompany",
    method: POST,
    successMessage: "Successfully added the Service Company",
    errorMessage: "Failed to add the Service Company"
  },
  appPersonRegister: {
    endPoint: "agencyUser/driverManagment/appPersonRegister?personObjectId=",
    method: POST,
    successMessage: "Person registered successfully",
    errorMessage: "Failed to register"
  },
  sendAppMessage: {
    endPoint: "agencyUser/driverManagment/sendAppMessage",
    method: POST,
    successMessage: "SMS sent successfully",
    errorMessage: "Failed to send SMS"
  },
  getAppHistory: {
    endPoint: "agencyUser/driverManagment/getAppMessages?personObjectId=",
    method: GET,
    // successMessage: "Fetched App message history list successfully",
    errorMessage: "Failed to fetch App message history list"
  },
  updateServiceCompany: {
    endPoint: "systemAdmin/systemUserManagement/updateServiceCompany",
    method: POST,
    successMessage: "Successfully updated the Service Company",
    errorMessage: "Failed to update the Service Company"
  },

  deleteServiceCompany: {
    endPoint: "systemAdmin/systemUserManagement/deleteServiceCompany",
    method: POST,
    successMessage: "Successfully deleted the Service Company",
    errorMessage: "Failed to delete the Service Company"
  },

  detailPersonSearch: {
    endPoint: "agencyUser/driverManagment/detailPersonSearch",
    method: POST,
    // successMessage: "Successfully fetched searched data",
    errorMessage: "Failed to fetch searched data"
  },
  confirmOrUnconfrimTimeOff: {
    endPoint: "agencyUser/driverManagment/confirmOrUnconfrimTimeOff",
    method: POST,
    successMessage: "Successully changed time off status",
    errorMessage: "Failed to change time off status"
  },
  addBookingImport: {
    endPoint: "agencyUser/driverManagment/addBookingImport?",
    method: POST,
    successMessage: "bookings imported Successfully",
    errorMessage: "Failed to import bookings"
  },
  getWeeklySingleBooking: {
    endPoint: "agencyUser/driverManagment/getWeeklySingleBooking",
    method: POST,
    successMessage: "got single booking Successfully",
    errorMessage: "Failed to get single booking"
  },
  changeSite: {
    endPoint: "agencyUser/bookingCalendar/changeBookingSite",
    method: POST,
    successMessage: "Site Details Changed Successfully",
    errorMessage: "Failed to Change Site Details"
  },
  listReports: {
    endPoint: "agencyUser/reportManagment/listReports",
    method: GET,
    // successMessage: "Successfully fetched report list ",
    errorMessage: "Failed to fetch report list"
  },
  reportInfo: {
    endPoint: "agencyUser/reportManagment/getReportInfo?reportObjectId=",
    method: GET,
    // successMessage: "Successfully fetched report Data ",
    errorMessage: "Failed to fetch report Data"
  },
  sendBulkSMS: {
    endPoint: "agencyUser/driverManagment/sendBulkSMS",
    method: POST,
    successMessage: "SMS Sent Successfully",
    errorMessage: "Failed to SMS Details"
  },
  getMonthlyBookingsOverview: {
    endPoint: "agencyUser/driverManagment/getMonthlyBookingsOverview",
    method: POST,
    // successMessage: "Fetched monthly booking list successfully",
    errorMessage: "Failed to fetch monthly booking list"
  },
  sendAvailabilityRequest: {
    endPoint: "agencyUser/driverManagment/sendAvailabilityRequest?personObjectId=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch availability template"
  },
  sendNoWorkTomorrow: {
    endPoint: "agencyUser/driverManagment/sendNoWorkTomorrow?personObjectId=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch No Work Tomorrow template"
  },
  sendCalendarUpdated: {
    endPoint: "agencyUser/driverManagment/sendCalendarUpdated?personObjectId=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch Calendar Updated template"
  },
  sendCalendarSummary: {
    endPoint: "agencyUser/driverManagment/sendCalendarSummary",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch Calendar Summary template"
  },
  sendBookingDetails: {
    endPoint: "agencyUser/driverManagment/sendBookingDetails",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch Booking Details template"
  },
  sendBookingHoursQuery: {
    endPoint: "agencyUser/driverManagment/sendBookingHoursQuery",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch Booking Hours Query template"
  },
  sendSMSToPhoneNumber: {
    endPoint: "agencyUser/driverManagment/sendSMSToPhoneNumber?smsContent=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to send message"
  },
  getCandidateLinks: {
    endPoint: "agencyUser/driverManagment/getCandidateLinks?personObjectId=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch candidate list"
  },

  getAccruedHolidayAdjustmentsList: {
    endPoint: "agencyUser/driverManagment/getAccruedHolidayAdjustmentsList?candidateObjectId=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch holiday adjustment list"
  },

  getHolidayShiftsAdjustmentsList: {
    endPoint: "agencyUser/driverManagment/getHolidayShiftsAdjustmentsList?candidateObjectId=",
    method: POST,
    // successMessage: "Successfully fetched",
    errorMessage: "Failed to fetch holiday shift list"
  },

  addAccruedHolidayAdjustments: {
    endPoint: "agencyUser/driverManagment/accruedHolidayAdjustments",
    method: POST,
    successMessage: "Successfully added holiday adjustment",
    errorMessage: "Failed to fetch add adjustment list"
  },

  addHolidayShiftsAdjustments: {
    endPoint: "agencyUser/driverManagment/holidayShiftsAdjustments",
    method: POST,
    successMessage: "Successfully added shift adjustment",
    errorMessage: "Failed to fetch shift adjustment"
  },

  deleteAccruedHolidayAdjustments: {
    endPoint:
      "agencyUser/driverManagment/deleteAccruedHolidayAdjustment?accruedHolidayAdjustmentObjectId=",
    method: POST,
    successMessage: "Successfully deleted from adjustment list",
    errorMessage: "Failed to delete adjustment list"
  },

  deleteHolidayShiftsAdjustments: {
    endPoint:
      "agencyUser/driverManagment/deleteHolidayShiftsAdjustment?holidayShiftsAdjustmentObjectId=",
    method: POST,
    successMessage: "Successfully deleted shift adjustment",
    errorMessage: "Failed to delete shift adjustment"
  },

  getStartDay: {
    endPoint: "systemAdmin/systemUserManagement/getStartDayOfWeek",
    method: GET,
    successMessage: "Successfully Fetched the Start day of Week",
    errorMessage: "Failed to fetch the Start Day"
  },

  sendPayDocuments: {
    endPoint: "financeUser/payrollManagement/sendPayDocumentEmail",
    method: POST,
    successMessage: "Successfully Sent the mail",
    errorMessage: "Failed to send Mail"
  },
  deleteMobileAppLink: {
    endPoint: "systemAdmin/systemUserManagement/deleteMobileAppLink",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to delete mobile app link"
  },
  updateMobileAppLink: {
    endPoint: "systemAdmin/systemUserManagement/updateMobileAppLink",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to update mobile app link"
  },
  updateReasonCode: {
    endPoint: "systemAdmin/systemUserManagement/updateReasonCode",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to update reason code"
  },
  deleteReasonCode: {
    endPoint: "systemAdmin/systemUserManagement/deleteReasonCode",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to delete reason code"
  },
  addReasonCode: {
    endPoint: "systemAdmin/systemUserManagement/addReasonCode",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to add reason code"
  },
  addMobileAppLink: {
    endPoint: "systemAdmin/systemUserManagement/addMobileAppLink",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to add mobile app link"
  },
  getAllReasonCodes: {
    endPoint: "systemAdmin/systemUserManagement/getAllReasonCodes",
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to fetch reason codes"
  },
  getAllMobileAppLinks: {
    endPoint: "systemAdmin/systemUserManagement/getAllMobileAppLinks",
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to fetch mobile app links"
  },
  getAllSiteListPaged: {
    endPoint: "agencyUser/driverManagment/getAllSiteListPaged",
    method: POST,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to fetch site list"
  },
  getAllSiteList: {
    endPoint: "agencyUser/driverManagment/getAllSiteList",
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to fetch site list"
  },
  updateNightOut: {
    endPoint: "agencyUser/bookingCalendar/updateNightOutDetails",
    method: POST,
    successMessage: "Night updated successfully",
    errorMessage: "Failed to update night out field"
  },
  holdFromApp: {
    endPoint: "agencyUser/driverManagment/holdFromApp",
    method: POST,
    successMessage: "hold from app updated successfully",
    errorMessage: "Failed to update hold from app"
  },
  getRtwCheck: {
    endPoint: "agencyUser/driverManagment/PersonTriggerYotiChecks?PersonObjectId=",
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to Trigger Yoti Checks."
  },
  getHolidayPeriod: {
    endPoint: "agencyUser/driverManagment/GetHolidayPeriod",
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to Get Holiday Period."
  },
  createDetailedBookings: {
    endPoint: "agencyUser/driverManagment/CreateDetailedBookings",
    method: POST,
    successMessage: "Created Detailed Bookings successfully",
    errorMessage: "Failed to create Detailed Bookings"
  },
  getCandidateDefaultExpense: {
    endPoint: "agencyUser/driverManagment/GetAllCandidateClientDefaultExpense",
    method: GET,
    // successMessage: "Fetched site list successfully",
    errorMessage: "Failed to Get Candidate Client Default Expenses."
  },
  candidateClientDefaultExpense: {
    endPoint: "agencyUser/driverManagment/CandidateClientDefaultExpense",
    method: POST,
    successMessage: "Action successfully Updated",
    errorMessage: "Failed to update Action."
  },
  setNewPassword: {
    endPoint: "/agencyUser/driverManagment/MRAppPersonSetPassword",
    method: GET,
    successMessage: "Password set Successfully",
    errorMessage: "Failed to set Password"
  },
  MRAppDetails: {
    endPoint: "/agencyUser/driverManagment/MRAppPersonAppDetails",
    method: GET,
    successMessage: "App Details Fetched Successfully",
    errorMessage: "Failed to Fetch App Details"
  },
  clearAppProfile: {
    endPoint: "/agencyUser/driverManagment/MRAppPersonClearAppProfile",
    method: GET,
    successMessage: "App profile Cleared Successfully",
    errorMessage: "Failed to Clear App Profile"
  },
  claimAPPId: {
    endPoint: "/agencyUser/driverManagment/MRAppPersonClaimAppID",
    method: GET,
    successMessage: "App profile id claimed Successfully",
    errorMessage: "Failed to Claim App Id"
  },
  clientCandidateHistory: {
    endPoint: "/agencyUser/driverManagment/ClientCandidateHistory",
    method: POST,
    errorMessage: "Failed to retrieve candidate Data"
  },
  clientCandidateEmail: {
    endPoint: "/agencyUser/driverManagment/sendEmailSelectedCandidates",
    method: POST,
    successMessage: "Email sent to all candidates successfully",
    errorMessage: "Failed to send email to Candidates"
  },
  mergeDocument: {
    endPoint: "/agencyUser/driverManagment/pay_MergePayDocuments",
    method: POST,
    successMessage: "Document Merged Successfully",
    errorMessage: "Failed to merge Document"
  },
  addPersonToLBAPI: {
    endPoint: "/agencyUser/driverManagment/addDrsPersonToLBapi",
    method: POST,
    successMessage: "Driver Added Successfully",
    errorMessage: "Failed to Add Driver"
  }
};

export default API_ENDPOINTS;
